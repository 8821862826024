<template>
  <BaseLayout
    theme-header="small"
    theme-footer="small"
    :show-content="cartInitialized"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import Layout from '@/mixins/layout';
import BaseLayout from '@/components/base-layout.vue';

export default {
  name: 'CheckoutLayout',
  components: {
    BaseLayout,
  },
  mixins: [Layout],
  computed: {
    ...mapGetters('cart', ['cartIsEmpty', 'cartInitialized']),
    isCartEmpty() {
      return this.cartIsEmpty && this.cartInitialized;
    },
  },
  watch: {
    isCartEmpty: {
      handler(value) {
        if (value) {
          this.$router.replace('/shop/warenkorb');
        }
      },
      immediate: true,
    },
  },
};
</script>
