import type { ListQuery, OfferQuery, ListQueryVariables } from '@/lib/goliath/goliath.types';

// Ist type contains all fields for an offer in the list
export type ListOffer = NonNullable<
  NonNullable<NonNullable<ListQuery['offers']>['entries']>[number]
>;

export type ListOfferProduct = NonNullable<ListOffer['product']>;

export type ListOfferStandardProduct = Extract<ListOfferProduct, { __typename: 'StandardProduct' }>;

export type ListOfferBundleProduct = Extract<ListOfferProduct, { __typename: 'BundleProduct' }>;

function isBundleProduct(product: ListOfferProduct): product is ListOfferBundleProduct {
  // eslint-disable-next-line no-underscore-dangle
  return product?.__typename === 'BundleProduct';
}

function isStandardProduct(product: ListOfferProduct): product is ListOfferStandardProduct {
  // eslint-disable-next-line no-underscore-dangle
  return product?.__typename === 'StandardProduct';
}

export const getListBundleProduct = (product: ListOfferProduct): ListOfferBundleProduct | null =>
  isBundleProduct(product) ? product : null;

export const getListStandardProduct = (
  product: ListOfferProduct
): ListOfferStandardProduct | null => (isStandardProduct(product) ? product : null);

// For ProductAlternatives
export type ScopedOffer = NonNullable<
  NonNullable<
    NonNullable<
      NonNullable<
        NonNullable<
          NonNullable<
            NonNullable<
              NonNullable<
                ListOffer | NonNullable<OfferQuery>['offerBySlugs']
              >['productAlternatives']
            >[number]
          >['properties']
        >[number]
      >['alternatives']
    >[number]
  >['offer']
>;

export default async (
  params: ListQueryVariables = {},
  progress = true,
  skipCache = false
): Promise<ListQuery['offers']> =>
  globalThis.$goliathApi<ListQueryVariables, ListQuery, 'offers'>(
    'list',
    params,
    'offers',
    progress,
    skipCache
  );
