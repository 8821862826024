<template>
  <BaseLayout
    class="layout--onlybranding"
    theme-footer="small"
    hide-navi
  />
</template>

<script>
import Layout from '@/mixins/layout';
import PageHeader from '~/components/page-header.vue';
import PageFooter from '~/components/page-footer.vue';
import BaseLayout from '~/components/base-layout.vue';

export default {
  name: 'OnlyBrandingLayout',
  components: {
    BaseLayout,
  },
  mixins: [Layout],
};
</script>

<style lang="scss" scoped>
.layout--onlybranding {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  :deep(.layout__container) {
    flex: 1 1 100%;
  }

  :deep(.page-header) {
    position: relative;
  }
}
</style>
