import type { ActionTree, GetterTree, MutationTree } from 'vuex';
import type { RootState } from '@/types/store-types';
import type { FetchError, MappedResponseType } from 'ofetch/dist';
import type { Cart, Item, PaymentType } from '@/lib/types/cartdata';
import { filter, fromPairs, groupBy, isEmpty } from 'lodash-es';
import ShopApi, { type BackendResponse } from '@/lib/shop-api';
import fetchOfferBySlugs, { getBundleProduct } from '@/lib/goliath/offer-by-slugs';
import { useNuxtApp } from '#imports';
import { isAllowedToAddToCart } from '~/lib/magenta-moments';

const CHECKOUT_INIT_URLS: Record<string, string> = {
  upgrade: 'kundenformular/upgrade-identifikation',
  newCustomer: 'kundenformular/persoenliche-daten',
  existingCustomer: 'kundenformular',
};

// FetchError is not supported server side.
class BackendError extends Error {
  public data: any;
}

const raiseBackendErrors = (data: BackendResponse) => {
  if (data.errors && Object.keys(data.errors).length > 0) {
    const error = new BackendError('Backend error');
    error.data = data;
    throw error;
  }
};

export type AddToCart = { quantity: number; articleNumber: string };

export interface CartState {
  cart: Cart | null;
  initialized: boolean;
  csrfToken: string | null;
  bundlesByArticleNumber: Record<string, Item[]>;
  addToCartQueue: {
    articleNumber?: string;
    position: number;
  };
}

interface CartGettersTypes {
  cartInitialized: boolean;
  cartItemsWithoutBundle: any[];
  isFreeOfChargeCart: boolean;
  hasTelekomProductInCart: boolean;
  hasBrodosProductInCart: boolean;
  isMixedCart: boolean;
  cartQuantity: number;
  cartIsEmpty: boolean;
  cartData: Cart | null;
  cartItems: Item[];
  checkoutInitUrlSuffix: string;
  possiblePaymentTypes: string[];
  paymentStatus: string | null;
  cartAmountReductionsIncluded: number;
  cartAmountReductionExcluded: number;
  subTotal: number;
  shippingCosts: number;
  totalPriceWithShipping: number;
  couponCampaignName: string;
  couponRedeemed: boolean;
  redeemedCouponCode: string | undefined;
  redeemedCouponType: string | undefined;
  itemsVoucherReductionAmount: number;
  voucherInitialSum: number;
  voucherRestSum: number;
  voucherReductionAmount: number;
  getAnonymousCartData: Record<string, any>;
  // paymentTypeRebate: boolean;
  paymentTypeRebateName: string | undefined;
  paymentTypeRebateReductionAmount: number | undefined;
  paymentTypeRebateButtontext: string | undefined;
  possiblePaymentTypeRebate: PaymentType | undefined;
  paymentTypeRebatePercentage: number | undefined;
  paymentTypeRebateItems: string[];
}

export type CartGetterTree = {
  [P in keyof CartGettersTypes]: CartGettersTypes[P];
};

export type CartGetters = {
  [P in keyof CartGettersTypes]: (state: CartState, getters: CartGetterTree) => CartGettersTypes[P];
};

function isRegularBundle(item: Item, state: CartState) {
  return Object.keys(state.bundlesByArticleNumber).includes(item.articleNumber);
}

let originalOperation: (() => Promise<MappedResponseType<'json', any>>) | null = null;

declare global {
  interface Window {
    testCustomerInQueue: number;
  }
}

const wait = (ms: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

let simulateBeQueue: boolean | undefined;
/**
 * retry request until BE adds item to cart: https://i22team.atlassian.net/wiki/spaces/I22SHOPLIFTERS/pages/2945777778/FE-Implementation
 */
const retryPromise = (
  operation: () => Promise<MappedResponseType<'json', any>>,
  errorCallback: (position: number) => void
): Promise<MappedResponseType<'json', any>> =>
  new Promise((resolve, reject) => {
    // [START] simulate be queue - when Feature Flag is active!
    if (simulateBeQueue === undefined) {
      simulateBeQueue = useNuxtApp().$featuresAsync.value?.simulateBeQueue?.enabled;
      window.testCustomerInQueue = 5;
    }
    if (simulateBeQueue) {
      if (!originalOperation) originalOperation = operation;
      if (window.testCustomerInQueue || 0 > 1) {
        operation = () => {
          const e = new Error('BE heavy Load queue simulation') as FetchError;
          // The second request is a 503 and should be ignored
          e.response = {
            // @ts-ignore
            headers:
              window.testCustomerInQueue === 2
                ? {}
                : { 'x-customer-in-queue': String(window.testCustomerInQueue) },
            data: null,
            status: window.testCustomerInQueue === 2 ? 503 : 429,
            statusText:
              window.testCustomerInQueue === 2 ? 'Service Unavailable' : 'Too many customers',
            config: {},
          };
          window.testCustomerInQueue -= 1;
          return Promise.reject(e);
        };
      } else {
        operation = originalOperation;
      }
    }
    // [END] simulate be queue

    operation()
      .then((response) => resolve(response))
      .catch((error) => {
        const queue = parseInt(error?.response?.headers?.['x-customer-in-queue'], 10);
        // use queue when we get a 50X from the BE (eg. 503 Service Unavailable)
        if (queue > 0 || error?.response?.status > 500 || error?.response?.statusCode > 500) {
          errorCallback(queue);
          return wait(15000)
            .then(retryPromise.bind(this, operation, errorCallback))
            .then(resolve)
            .catch(reject);
        }
        errorCallback(0);
        return reject(error);
      });
  });

export const state = (): CartState => ({
  cart: null,
  initialized: false,
  csrfToken: null,
  bundlesByArticleNumber: {},
  addToCartQueue: {
    position: 0,
    articleNumber: undefined,
  },
});

export const mutations: MutationTree<CartState> = {
  SET_CART(state, cart: Cart) {
    state.cart = cart;
    state.initialized = true;
  },
  SET_CSRF_TOKEN(state, csrfToken: string) {
    state.csrfToken = csrfToken;
  },
  ADD_PRODUCT_BUNDLES(state, productBundles: Record<string, Item[]>) {
    Object.keys(productBundles).forEach((articleNumber) => {
      state.bundlesByArticleNumber[articleNumber] = productBundles[articleNumber];
    });
  },
  UPDATE_QUEUE(
    state,
    { position, articleNumber }: { position: number | undefined; articleNumber?: string }
  ) {
    state.addToCartQueue = {
      articleNumber,
      position:
        position !== undefined && !Number.isNaN(position)
          ? position
          : state.addToCartQueue.position || 999,
    };
  },
};

export const getters: GetterTree<CartState, RootState> & CartGetters = {
  addToCartQueue(state) {
    return state.addToCartQueue;
  },
  cartInitialized(state) {
    return state.initialized;
  },
  cartItemsWithoutBundle(state, getters) {
    return getters.cartItems.filter((item) => !isRegularBundle(item, state));
  },
  isFreeOfChargeCart(_state, getters) {
    return getters.cartItems.length > 0 && getters.totalPriceWithShipping === 0;
  },
  hasTelekomProductInCart(_state, getters) {
    return getters.cartItems.some(({ provider }) => provider === 'TELEKOM');
  },
  hasBrodosProductInCart(_state, getters) {
    return getters.cartItems.some(({ provider }) => provider === 'BRODOS');
  },
  isMixedCart(_state, getters) {
    return getters.hasBrodosProductInCart && getters.hasTelekomProductInCart;
  },
  cartQuantity(_state, getters) {
    return getters.cartItems.reduce((totalQuantity, item) => totalQuantity + item.quantity, 0);
  },
  cartIsEmpty(_state, getters) {
    return getters.cartQuantity === 0;
  },
  cartData(state): Cart | null {
    return state.cart && Object.keys(state.cart).length > 0 ? state.cart : null;
  },
  cartItems(_state, getters) {
    return getters.cartData?.items || [];
  },
  checkoutInitUrlSuffix() {
    return CHECKOUT_INIT_URLS.newCustomer;
  },
  possiblePaymentTypes(_state, getters) {
    return getters.cartData?.possiblePaymentTypes || [];
  },
  paymentType(_state, getters) {
    return getters.cartData?.paymentType || null;
  },
  paymentStatus(_state, getters) {
    return getters.cartData?.state || null;
  },
  cartAmountReductionsIncluded(_state, getters) {
    return getters.cartData?.amount || 0;
  },
  cartAmountReductionExcluded(_state, getters) {
    return getters.subTotal;
  },
  subTotal(_state, getters) {
    return getters.cartData?.subTotal || 0;
  },
  shippingCosts(_state, getters) {
    if (!getters.cartData) return 0;
    const { shippingCostsTelekom, shippingCostsBrodos } = getters.cartData;
    const shippingCosts = [shippingCostsTelekom, shippingCostsBrodos];
    return shippingCosts.reduce((sum, curr) => {
      if (typeof curr === 'number') return sum + curr;
      return sum;
    }, 0);
  },
  totalPriceWithShipping(_state, getters) {
    return getters.cartAmountReductionsIncluded + getters.shippingCosts;
  },
  couponCampaignName(_state, getters) {
    return getters.cartData?.redeemedCouponDescription || '';
  },
  couponRedeemed(_state, getters) {
    if (!getters.cartData?.redeemedCoupon) return false;
    return getters.cartData.redeemedCoupon.length > 0;
  },
  redeemedCouponCode(_state, getters) {
    return getters.cartData?.redeemedCoupon || undefined;
  },
  redeemedCouponType(_state, getters) {
    return getters.cartData?.redeemedCouponType || undefined;
  },
  itemsVoucherReductionAmount(_state, getters) {
    // Products will receive coupon reductions via a reductionAmount property on the item itself
    // Only Brodos Products will receive voucher reductions (not coupon reductions) from the whole cart (i.e. getters.voucherReductionAmount)
    return getters.cartItems
      .filter((item) => item.voucher && item.provider === 'TELEKOM')
      .reduce((sum: number, item: Item) => sum + item.reductionAmount, 0);
  },
  voucherInitialSum(_state, getters) {
    return getters.cartData?.voucherInitialSum || 0;
  },
  voucherRestSum(_state, getters) {
    return getters.cartData?.voucherRestSum || 0;
  },
  voucherReductionAmount(_state, getters) {
    return (getters.cartData?.voucherReductionAmount || 0) + getters.itemsVoucherReductionAmount;
  },
  getAnonymousCartData(_state, getters): Record<string, any> {
    if (getters.cartData === null) return {};
    const validKeys = [
      'id',
      'orderKey',
      'state',
      'paymentType',
      'originalAmount',
      'amount',
      'redeemedCoupon',
      'redeemedCouponDescription',
      'shippingCostsTelekom',
      'shippingCostsBrodos',
      'possiblePaymentTypes',
      'jointCart',
      'paymentLinkTelekom',
      'paymentLinkBrodos',
      'paidTelekom',
      'paidBrodos',
      'branding',
      'voucherRestSum',
      'voucherReductionAmount',
      'redeemedCouponType',
      'voucherInitialSum',
      'gk',
      'paymentTypeRebateName',
      'paymentTypeRebateReductionAmount',
      'paymentTypeRebateButtontext',
      'possiblePaymentTypeRebate',
      'paymentTypeRebatePercentage',
      'paymentTypeRebateItems',
    ];

    const data = Object.fromEntries(
      Object.entries(getters.cartData).filter(([key]) => validKeys.includes(key))
    );
    data.items = getters.cartItems.map(
      ({ name, quantity, articleNumber, price, coupon, voucher, rebateName }) =>
        `Name: "${name}"; Quantity: ${quantity}; ArticleNumber: ${articleNumber}; Price: ${price}; Coupon: ${coupon}; Voucher: ${voucher}; RebateName: ${rebateName}`
    );
    return data;
  },
  addresses(_state, getters) {
    return getters.cartData?.addressesAttributes || [];
  },
  wasPaypalExpressReceived(_state, getters) {
    return getters.paymentStatus === 'paypal_express_address_received';
  },
  paymentTypeRebateName(_state, getters) {
    return getters.cartData?.paymentTypeRebateName || undefined;
  },
  possiblePaymentTypeRebate(_state, getters) {
    return (getters.cartData?.possiblePaymentTypeRebate as PaymentType) || undefined;
  },
  paymentTypeRebateReductionAmount(_state, getters) {
    return getters.cartData?.paymentTypeRebateReductionAmount || undefined;
  },
  paymentTypeRebatePercentage(_state, getters) {
    return getters.cartData?.paymentTypeRebatePercentage || undefined;
  },
  paymentTypeRebateButtontext(_state, getters) {
    return getters.cartData?.paymentTypeRebateButtontext || undefined;
  },
  paymentTypeRebateItems(_state, getters) {
    return getters.cartData?.paymentTypeRebateItems || [];
  },
};

export const actions: ActionTree<CartState, RootState> = {
  initCart(context) {
    if (context.state.initialized) return Promise.resolve(context.state.cart);
    return context.dispatch('fetchCart', false);
  },
  async clearCart(context) {
    context.commit('SET_CART', null);
    return ShopApi.deleteOrder();
  },
  async fetchCart(context, showLoadingSpinner = true) {
    try {
      const data = await ShopApi.getCurrentCart(showLoadingSpinner);
      if (!data.order) return undefined;
      context.commit('SET_CART', data.order);
      if (data.order?.redeemedCoupon) {
        context.dispatch('coupon/initializeCoupon', data.order.redeemedCoupon, { root: true });
      } else {
        context.dispatch('coupon/clearCoupon', true, {
          root: true,
        });
      }
      const allBundleSlugs = data.order.items
        .filter(({ bundleType }: Item) => bundleType === 'brodos')
        .map(({ slug }: Item) => slug);
      await context.dispatch('queryBrodosBundleSlugs', allBundleSlugs);
      return data;
    } catch (e) {
      console.error(e);
      /* lot loaded */
    }

    return undefined;
  },
  async updateCartItems(context, items: AddToCart[]) {
    const data = await ShopApi.items(items);
    if (data.order) {
      context.commit('SET_CART', data.order);
    }
    raiseBackendErrors(data);

    return data;
  },
  async addCartItems(context, items: AddToCart[]) {
    // When we have a Moments product, we have to check the correct URL
    if (
      items.filter((item) =>
        isAllowedToAddToCart(item.articleNumber, this.$router?.currentRoute?.path)
      ).length !== items.length
    ) {
      const error = new BackendError('Not allowed');
      error.data = {
        errors: {
          moments: [
            {
              error: 'not_allowed',
            },
          ],
        },
      };
      throw error;
    }

    return retryPromise(
      (): Promise<MappedResponseType<'json', BackendResponse>> =>
        context.dispatch('updateCartItems', items),
      (position) => {
        context.commit('UPDATE_QUEUE', {
          position,
          articleNumber: items?.length === 1 ? items[0].articleNumber : undefined,
        });
      }
    ).finally(() => {
      context.commit('UPDATE_QUEUE', { position: 0 });
    });
  },
  async cartAddCoupon(context, { redeemedCoupon }: { redeemedCoupon: string }) {
    try {
      await ShopApi.addCouponToCart({ redeemedCoupon });
    } finally {
      await context.dispatch('fetchCart');
    }
  },
  async updateCustomerData(context, order: Partial<Cart>) {
    const data = await ShopApi.updateCustomerData(order);
    await context.dispatch('fetchCart');
    return data;
  },

  async queryBrodosBundleSlugs(context, bundleSlugs: string[]) {
    const bundleUpdatesList = await Promise.all(
      bundleSlugs.map(async (bundleSlug) => {
        if (context.state.bundlesByArticleNumber[bundleSlug]) return [];

        const offer = await fetchOfferBySlugs({ productSlug: bundleSlug });
        if (!offer?.product) return [];
        const bundleProduct = getBundleProduct(offer?.product);
        if (!bundleProduct) return [];

        const { articleNumber, bundledProducts } = bundleProduct;

        const itemsGroupedByAN = groupBy(bundledProducts, 'articleNumber');
        const countedItems = Object.values(itemsGroupedByAN).map((group) => ({
          ...group[0],
          quantity: group.length,
        })) as unknown as Item[];
        return [articleNumber, countedItems];
      })
    );

    // [[], ['some', 'entry'] => {'some': 'entry'}
    const bundleUpdates = fromPairs(filter(bundleUpdatesList, (l) => !isEmpty(l)));

    if (isEmpty(bundleUpdates)) return;
    context.commit('ADD_PRODUCT_BUNDLES', bundleUpdates);
  },
  async deleteCoupon({ dispatch }) {
    try {
      await ShopApi.deleteCoupon();
      dispatch('coupon/clearCoupon', true, {
        root: true,
      });
    } catch (_e) {
      /* no-op */
    } finally {
      await dispatch('fetchCart');
    }
  },
};
