<template>
  <div
    v-if="showDelayedLoading"
    class="loading-spinner-page"
  >
    <LoadingSpinner />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { debounce } from 'throttle-debounce';
import LoadingSpinner from '~/components/loading-spinner.vue';

export default {
  components: { LoadingSpinner },
  computed: {
    ...mapGetters('loader', ['isLoading']),
    ...mapGetters('backdrop', { hasActiveBackdrop: 'isActive' }),
    showLoading() {
      // "isLoading" is set from axios for goliath / backend.
      return (this.nuxtLoading || this.isLoading) && !this.hasActiveBackdrop;
    },
  },
  watch: {
    showLoading(state) {
      if (this.delayedLoading && this.delayedLoading.cancel) {
        this.delayedLoading.cancel();
      }
      if (state) {
        // Delay the spinner. Since the full page cache is fast, the loading spinner might flicker.
        this.delayedLoading = debounce(100, () => {
          this.showDelayedLoading = true;
        });
        this.delayedLoading();
      } else {
        this.showDelayedLoading = false;
      }
    },
  },
  data: () => ({
    nuxtLoading: false,
    delayedLoading: null,
    showDelayedLoading: false,
  }),
  methods: {
    start() {
      this.nuxtLoading = true;
    },
    finish() {
      this.nuxtLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@use 'assets/base' as *;

.loading-spinner-page {
  background-color: rgba(color(white), 0.5);
  display: flex;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: $z-index-loader;
  pointer-events: auto;
  justify-content: center;
  align-items: center;
}
</style>
