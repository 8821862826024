import type { OfferQuery, OfferQueryVariables } from '@/lib/goliath/goliath.types';

export type Offer = NonNullable<NonNullable<OfferQuery>['offerBySlugs']>;

export type OfferProduct = NonNullable<Offer['product']>;

export type OfferStandardProduct = Extract<OfferProduct, { __typename: 'StandardProduct' }>;

export type OfferBundleProduct = Extract<OfferProduct, { __typename: 'BundleProduct' }>;

export function isBundleProduct(product: OfferProduct): product is OfferBundleProduct {
  return product.bundle === true;
}

export function isStandardProduct(product: OfferProduct): product is OfferStandardProduct {
  return !product.bundle;
}

export const getBundleProduct = (product: OfferProduct): OfferBundleProduct | null =>
  isBundleProduct(product) ? product : null;

export const getStandardProduct = (product: OfferProduct): OfferStandardProduct | null =>
  isStandardProduct(product) ? product : null;

export default async (
  params: OfferQueryVariables,
  progress = true
): Promise<OfferQuery['offerBySlugs'] | undefined> => {
  const data = await globalThis.$goliathApi<OfferQueryVariables, OfferQuery, 'offerBySlugs'>(
    'offer-by-slugs',
    params,
    'offerBySlugs',
    progress
  );
  return data?.product ? data : undefined;
};
