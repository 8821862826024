<template>
  <div class="layout">
    <PageHeader
      :theme="themeHeader"
      :hide-navi="hideNavi"
    />
    <client-only>
      <!-- Show popup only when nav is shown. -->
      <!-- <template v-if="themeHeader == null">
        <MagentaBlackDaysPopup />
      </template> -->
    </client-only>
    <nuxt
      v-show="showContent"
      class="layout__container"
    />
    <PageFooter :theme="themeFooter" />
    <DialogBackdrop />
    <client-only>
      <CustomerQueueDialog />
    </client-only>
    <portal-target name="globals" />
    <GlobalModal />
    <template v-if="isDev">
      <StatusToolbarWrapper />
    </template>
  </div>
</template>

<script setup lang="ts">
import type { AsyncComponent } from 'vue';
import { useRuntimeConfig, defineAsyncComponent } from '#imports';
import DialogBackdrop from '@/components/dialog-backdrop.vue';
import PageHeader from '@/components/page-header.vue';
import PageFooter from '@/components/page-footer.vue';
// import DefaultPopup from '@/components/default-popup.vue';
// import MagentaBlackDaysPopup from '@/components/magenta-black-days/magenta-black-days-popup.vue';
import CustomerQueueDialog from '@/components/customer-queue-dialog.vue';
import GlobalModal from '~/components/global-modal.vue';

const isDev = !useRuntimeConfig().mode.isProduction;

let StatusToolbarWrapper: AsyncComponent | null = null;

if (isDev) {
  StatusToolbarWrapper = defineAsyncComponent(
    () => import('@/components/status-toolbar-wrapper.vue')
  );
}

defineProps({
  themeHeader: { type: String, default: null },
  themeFooter: { type: String, default: 'standard' },
  showContent: { type: Boolean, default: true },
  hideNavi: { type: Boolean },
});
</script>

<style lang="scss" scoped>
.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  &__container {
    flex: 1;
  }
}
</style>
