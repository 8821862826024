import { getPriceFormatted as getPriceFormattedRocket, defaultPriceOptions } from '@i22/rocket';
import type { Options } from '@i22/rocket/dist/price-utils/types';
import { getRebatePercentage, getRebatePrices } from '@/lib/rebate-helper';
import type { Rebate } from '@/store/offers';
import type { Maybe, Offer, PaymentFrequency, Price, PriceType } from '@/lib/goliath/goliath.types';

function getPricesFromOfferWithRebate(offer: Offer, rebate: Rebate, quantity = 1) {
  if (!rebate?.staggereds || !offer.prices) {
    return offer.prices;
  }

  const rebatePercentage = getRebatePercentage(rebate.staggereds, quantity);

  return getRebatePrices(offer.prices as Price[], rebatePercentage);
}

function getPriceFormatted(
  prices: (Price | Maybe<Price>)[],
  type: PriceType | 'DEFAULT' | 'WAS',
  paymentFrequency: PaymentFrequency | 'ONETIME' | 'MONTHLY' = 'ONETIME',
  options: Options = defaultPriceOptions
) {
  return getPriceFormattedRocket(prices, type, paymentFrequency, options);
}

function getPriceFromOfferFormatted(
  offer: Offer,
  type: PriceType | 'DEFAULT' | 'WAS',
  paymentFrequency: PaymentFrequency | 'ONETIME' | 'MONTHLY' = 'ONETIME',
  options: Options = defaultPriceOptions
) {
  if (!offer.prices) return undefined;
  return getPriceFormatted(offer.prices, type, paymentFrequency, options);
}

export { getPricesFromOfferWithRebate, getPriceFormatted, getPriceFromOfferFormatted };
