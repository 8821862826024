<template>
  <footer class="footer">
    <div class="page-footer">
      <FooterColumns v-if="standardTheme" />
    </div>

    <div class="page-footer-lpg">
      <FooterBrand />
      <FooterCorporate />
    </div>
  </footer>
</template>

<script>
import FooterCorporate from '@/components/footer-corporate.vue';
import FooterBrand from '@/components/footer-brand.vue';
import FooterColumns from '@/components/footer-columns.vue';

const POSSIBLE_THEMES = ['standard', 'small'];

export default {
  name: 'PageFooter',
  components: {
    FooterColumns,
    FooterBrand,
    FooterCorporate,
  },
  props: {
    theme: {
      type: String,
      default: 'standard',
      validator: (value) => POSSIBLE_THEMES.includes(value),
    },
  },
  computed: {
    smallTheme() {
      return this.theme === 'small';
    },
    standardTheme() {
      return this.theme === 'standard';
    },
  },
};
</script>

<style lang="scss" scoped>
@use 'assets/base' as *;

.footer {
  position: relative;
}

.page-footer {
  background-color: var(--color-primary);
  padding-top: 2 * $grid-base;
  @include mq($mq-md) {
    padding-top: 5 * $grid-base;
  }

  :deep(.shop-finder) {
    margin-top: $grid-medium-gutter;
  }
}

.page-footer-lpg {
  background-color: color(grey, 10000);
  color: color(white);
}
</style>
