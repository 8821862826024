<template>
  <Modal ref="modal">
    <div
      v-if="showModal"
      class="customer-queue-dialog"
    >
      <Heading
        align="center"
        tag="h4"
        theme="primary"
        spaces-bottom
      >
        Bitte hab ein wenig Geduld!
      </Heading>
      <p>
        Im Moment werden viele Bestellungen gleichzeitig aufgegeben. Du kannst Deine Bestellung in
        Kürze ausführen.
      </p>

      <div
        v-if="offer"
        class="customer-queue-dialog__article"
      >
        <GoliathImageElement
          v-if="offerImage"
          :alt="offerName"
          :image="offerImage"
          render-without-figure
          class="customer-queue-dialog__article-image"
        />
        <div class="customer-queue-dialog__article-info">
          <Heading tag="h5">{{ offerName }}</Heading>
          <PricesContainer
            class="customer-queue-dialog__price"
            :prices-data="offer.prices || []"
          />
        </div>
      </div>
      <SvgIcon
        v-else
        :image="CartWaiting"
        no-icon-class
        class="customer-queue-dialog__cart-icon"
      />

      <p
        v-if="addToCartQueue.position !== 999"
        class="customer-queue-dialog__queue-text"
      >
        Noch <strong>{{ addToCartQueue.position }}</strong> Bestellung{{
          addToCartQueue.position == 1 ? '' : 'en'
        }}
        in der Warteschlange
      </p>

      <div class="customer-queue-dialog__spinner">
        <span class="customer-queue-dialog__spinner-dot" />
        <span class="customer-queue-dialog__spinner-dot" />
        <span class="customer-queue-dialog__spinner-dot" />
        <span class="customer-queue-dialog__spinner-dot" />
        <span class="customer-queue-dialog__spinner-dot" />
      </div>
    </div>
  </Modal>
</template>

<script lang="ts" setup>
import { useNuxtApp } from '#imports';
import { computed, watch, ref } from 'vue';
import openable from '@/mixins/openable';
import CartWaiting from '@/assets/images/cart-waiting.svg';
import { Heading, GoliathImageElement, Modal } from '@i22-td-smarthome/component-library';
import fetchOfferByNumbers from '@/lib/goliath/offer-by-article-number';
import PricesContainer from '@/components/product-detail/prices-container.vue';
import type { OfferByArticleNumberQuery } from '@/lib/goliath/goliath.types';
import { getMainImageFromOffer } from '~/lib/goliath-media';
import type { ListOffer } from '~/lib/goliath/offers';

const store = useNuxtApp().$store;

const addToCartQueue = computed(() => store.getters['cart/addToCartQueue']);

const showModal = computed<boolean>(() => addToCartQueue.value.position > 0);

const modal = ref<(typeof Modal.methods & typeof openable.methods) | null>(null);

const offer = ref<OfferByArticleNumberQuery['offer'] | undefined>(undefined);

const offerImage = computed(() => {
  if (!offer.value) return undefined;
  return getMainImageFromOffer(offer.value as ListOffer);
});

const offerName = computed(
  () => offer.value?.product?.family?.name || offer.value?.product?.name || ''
);

watch(addToCartQueue, async (newValue, oldValue) => {
  if (!modal.value) return;
  if (showModal.value) {
    modal.value.openDialog();
    if (newValue.articleNumber && newValue.articleNumber !== oldValue.articleNumber) {
      try {
        offer.value = await fetchOfferByNumbers({
          articleNumber: newValue.articleNumber as string,
        });
      } catch (_e) {}
    }
    const dialog = modal.value?.$el?.querySelector('.base-dialog__dialog');
    if (dialog) {
      dialog.addEventListener('close', (event) => {
        if (!event) return;
        window?.location?.reload(); // reload window to end queue. Stopping it via code would be too heavy.
      });
    }
    return;
  }
  modal.value.closeDialog();
});
</script>

<style lang="scss">
@use 'assets/base' as *;
.customer-queue-dialog {
  text-align: center;

  margin: $grid-base * 2 auto;

  @keyframes dot-flashing {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    65% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }

  &__article {
    border: 1px solid color(grey, 20000);
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    grid-gap: $grid-base * 2;
    padding: $grid-base;
    margin-block: $grid-base * 2;

    @include mq($mq-sm) {
      grid-gap: $grid-base * 4;
      padding: $grid-base * 2;
    }

    &-info {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }

    &-image {
      max-width: 50%;
      object-fit: contain;
    }
  }

  &__price {
    padding-bottom: 2px;

    .prices {
      padding-bottom: 0;

      .price {
        flex-wrap: nowrap;
      }
    }
  }

  &__cart-icon {
    width: 30%;
    margin-block: $grid-base * 2;
  }

  &__queue-text strong {
    color: var(--color-primary);
  }

  &__spinner {
    display: flex;
    justify-content: center;
    grid-gap: $grid-base;

    @media (prefers-reduced-motion) {
      display: none;
    }

    &-dot {
      display: block;
      width: 15px;
      height: 15px;
      background-color: color(grey, 23000);
      animation: dot-flashing 1.5s infinite;

      &:nth-child(2) {
        background-color: color(grey, 21000);
        animation-delay: 0.2s;
      }
      &:nth-child(3) {
        background-color: color(grey, 19000);
        animation-delay: 0.4s;
      }
      &:nth-child(4) {
        background-color: color(grey, 17000);
        animation-delay: 0.6s;
      }
      &:nth-child(5) {
        background-color: var(--color-primary);
        animation-delay: 0.8s;
      }
    }
  }
}
</style>
