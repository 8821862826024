<template>
  <GridContainer class="footer-corporate">
    <GridRow>
      <GridColumn class="footer-corporate__content">
        <a
          class="footer-corporate__copyright footer-corporate__link"
          href="https://www.telekom.de/ueber-das-unternehmen"
          rel="noopener"
          target="_blank"
        >
          &copy; Telekom Deutschland GmbH
        </a>

        <ul class="footer-corporate__list">
          <li
            v-for="(item, index) in navList"
            :key="index"
            class="footer-corporate__list-item"
          >
            <a
              :href="item.url"
              class="footer-corporate__link"
              rel="noopener"
              target="_blank"
              :data-testid="getDataId(item)"
            >
              {{ item.label }}
            </a>
          </li>
        </ul>
      </GridColumn>
    </GridRow>
  </GridContainer>
</template>

<script>
import { GridColumn, GridContainer, GridRow } from '@i22-td-smarthome/component-library';

export default {
  name: 'FooterCorporate',
  components: {
    GridRow,
    GridColumn,
    GridContainer,
  },
  data() {
    return {
      navList: [
        {
          label: 'Impressum',
          url: 'https://www.telekom.de/impressum',
        },
        {
          label: 'Datenschutz',
          url: 'https://www.telekom.de/ueber-das-unternehmen/datenschutz',
        },
        {
          label: 'AGB',
          url: 'https://www.telekom.de/is-bin/INTERSHOP.enfinity/WFS/EKI-PK-Site/-/-/-/ViewAGB-TextQuery?SearchText=&wt_mc=alias_agb',
        },
        {
          label: 'Produktinformationsblatt',
          url: 'https://www.telekom.de/produktinformationsblatt',
        },
        {
          label: 'Verbraucherinformationen',
          url: 'https://www.telekom.de/ueber-das-unternehmen/verbraucherinformationen',
        },
        {
          label: 'Jugendschutz',
          url: 'https://www.telekom.de/ueber-das-unternehmen/jugendschutz',
        },
        {
          label: 'Hinweise ElektroG/BattG',
          url: 'https://www.telekom.de/ueber-das-unternehmen/hinweise-elektrogeraete-batterie-rueckgabe',
        },
      ],
    };
  },
  methods: {
    getDataId(navItem) {
      const lowercaseLabel = navItem?.label?.toLowerCase();
      return lowercaseLabel && `corporate-footer-${lowercaseLabel}-link`;
    },
  },
};
</script>

<style lang="scss" scoped>
@use 'assets/base' as *;

.footer-corporate {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-bottom: $grid-base * 3;
  font-size: 15px;
  line-height: 1.2;
  max-width: $max-navbar-width + $grid-base * 4;

  @include mq($mq-sm) {
    padding-bottom: $grid-base * 4;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    margin-left: $grid-base;
    margin-right: $grid-base;
    @include mq($mq-sm) {
      margin-inline: $grid-base;
    }

    @include mq($mq-md) {
      margin-inline: $grid-base * 2;
    }

    @include mq($mq-lg) {
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    @include mq($mq-sm) {
      flex-wrap: wrap;
      flex-direction: row;
      grid-gap: 0 $grid-base * 2;
    }

    &-item {
      list-style: none;
      padding: 3px 0;
    }
  }

  &__link {
    color: color(white);
    display: block;

    &:link,
    &:visited,
    &:hover,
    &:active {
      color: color(white);
    }

    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
