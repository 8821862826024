import { type CustomVue } from 'vuex';
import { stringifyQuery } from 'ufo';
import { ofetch } from 'ofetch';
import { defineNuxtPlugin } from '#imports';
import { captureException } from '~/lib/sentry';
import { validate } from '~/lib/validate-goliath-response';
import type { OperationVariables } from '@apollo/client';
import type { GoliathApiClient } from '~/types/goliath-api';

declare global {
  // eslint-disable-next-line vars-on-top, no-var
  var $goliathApi: GoliathApiClient;
}

// We declare $goliathApi for frontend use.
const buildApiClient =
  (store: CustomVue): GoliathApiClient =>
  async <V, R, K extends keyof NonNullable<R>>(
    operation: string,
    variables: V,
    responseKey: K,
    progress: boolean | undefined = undefined,
    skipCache = false
  ): Promise<NonNullable<NonNullable<R>[K]> | undefined> => {
    const contexts = {
      operation: { operation, isClient: true },
      params: { params: JSON.stringify(variables) },
    };
    const config: Record<string, any> = {}; // RequestConfig
    if (skipCache) {
      config.headers = {
        'x-use-cache': false,
      };
    }

    // const { start, finish } = useLoadingIndicator();
    const start = () => store.dispatch('loader/start');
    const finish = () => store.dispatch('loader/stop');

    try {
      if (progress) {
        start();
      }
      let response: R;
      // if the query is too lang, we have to switch to post.
      if (stringifyQuery(variables as Record<any, any>).length > 4000) {
        response = (await ofetch(`/api/goliath/${operation}`, {
          method: 'POST',
          body: variables as OperationVariables,
        })) as R;
      } else {
        response = (await ofetch(`/api/goliath/${operation}`, {
          method: 'GET',
          query: variables as OperationVariables,
        })) as R;
      }

      return validate<R, K>(response, responseKey, contexts);
    } catch (e: Error | any) {
      const status = e.status || e.statusCode || e.response?.status;
      // Check, if the error was already send to sentry in brontend.
      if (status !== 404 && !e.data?.data?.isHandledBySentry) {
        captureException(e, {
          contexts,
        });
      }
    } finally {
      if (progress) {
        finish();
      }
    }

    return undefined;
  };

export default defineNuxtPlugin((nuxtApp) => {
  const store = nuxtApp.$store as CustomVue;
  globalThis.$goliathApi = buildApiClient(store);
});
