// @ts-ignore
import SavedFeatures from '@i22/status-toolbar/dist/saved-features';
import type { Mode } from '@/lib/mode-setup';

export type Feature = {
  enabled: boolean;
};

export type Features = Record<string, Feature> & {
  defaultFeatures: Record<string, Feature>;
};

export default (mode: Mode): Features => {
  const onlyDevelopment = mode.name === 'development';
  const onlyDevelopmentAndPreview = !(mode.isProduction || mode.name === 'staging');

  const featureList: Record<string, Feature> = {
    handyVergleich: {
      // https://jira.i22.de/browse/EGS-1612
      enabled: onlyDevelopmentAndPreview,
    },
    simulateBeQueue: {
      enabled: false,
    },
    dcbMailSent: {
      // https://i22-telekom-longtail.atlassian.net/browse/EGS-2362
      enabled: false,
    },
  };

  return SavedFeatures.getFeatures(featureList, mode.isProduction) as Features;
};
