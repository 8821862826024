<template>
  <div class="primary-navigation">
    <div class="primary-navigation__tabs">
      <span class="primary-navigation__tab-entry primary-navigation__tab-entry--active">
        Privatkunden
      </span>
      <a
        class="primary-navigation__tab-entry"
        href="https://geschaeftskunden.telekom.de/"
        rel="noopener"
        target="_blank"
      >
        Geschäftskunden <span class="sr-only">(Der Link wird in einem neuen Tab geöffnet)</span>
        <SvgIcon
          :image="externalIcon"
          aria-hidden="true"
        />
      </a>
    </div>
    <div
      class="primary-navigation__navigation-items-wrapper"
      :class="wrapperClass"
    >
      <NavigationItem
        v-for="navigationItem in $store.getters['navigation/navigationItems']"
        :key="navigationItem.path"
        :navigation-item="navigationItem"
        @subnav-open="handleSubmenuToggle"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { useNuxtApp } from '#imports';
import NavigationItem from './navigation-item-lazy';
import externalIcon from '@/assets/images/external-link.svg';

const showSubNav = ref(0);

const { $store } = useNuxtApp();

const wrapperClass = computed(() => {
  if (showSubNav.value === 0) return '';
  return `primary-navigation__navigation-items-wrapper--open-${showSubNav.value}`;
});
const handleSubmenuToggle = (value) => {
  showSubNav.value = value;
};
</script>

<style lang="scss" scoped>
@use 'assets/base' as *;

.primary-navigation {
  top: 100%;
  left: 0;
  right: 0;

  @include mq(false, $mq-lg - 1) {
    position: absolute;
    background: var(--color-bg);
    color: var(--color-font);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding-bottom: $grid-base * 4;
    height: calc(100vh - 60px);
  }

  @include mq($mq-lg) {
    display: inline-flex;
  }

  &__tabs {
    background: var(--color-background-subtle);
    padding-top: $grid-base * 2;
    display: flex;
    @include mq($mq-lg) {
      display: none;
    }
  }

  &__tab-entry {
    flex: 1 1 50%;
    @include font-size(18px, 21px);
    font-weight: 800;
    display: block;
    text-align: center;
    height: 40px;
    padding: 0 6px;
    border-bottom: 1px solid var(--color-stroke-subtle);
    color: var(--color-font-subtle);

    &--active {
      border-color: var(--color-primary);
      color: var(--color-primary);
    }
  }

  &__navigation-items-wrapper {
    @include mq(false, $mq-lg - 1) {
      margin-bottom: calc(
        env(safe-area-inset-bottom, 0) + 80px
      ); // add the bottom bar on Chrome / Brave
      transition: transform 0.4s ease-out;

      &--open-1 {
        transform: translateX(-100%);
      }
      &--open-2 {
        transform: translateX(-200%);
      }
    }

    @include mq($mq-lg) {
      display: flex;
      align-items: center;
    }
  }
}
</style>
