<template>
  <div class="secondary-navigation">
    <div class="secondary-navigation__search-form" />
    <ul class="secondary-navigation__list">
      <li class="secondary-navigation__link-wrapper">
        <BookmarkIndicator
          class="secondary-navigation__link secondary-navigation__link--bookmark"
          icon-class="secondary-navigation__icon"
        />
      </li>
      <li class="secondary-navigation__link-wrapper">
        <SearchButton
          ref="searchButton"
          class="secondary-navigation__link"
          data-tealium-rel="navi-top.button.oeffne-suchformular"
          icon-class="secondary-navigation__icon"
          @click="loadNexusSearch"
        />
      </li>
      <li class="secondary-navigation__link-wrapper">
        <CartIndicator
          class="secondary-navigation__link"
          icon-class="secondary-navigation__icon"
        />
      </li>
      <li class="secondary-navigation__menu secondary-navigation__link-wrapper">
        <div
          ref="menuToggle"
          class="secondary-navigation__link"
          :class="{ 'secondary-navigation__link--active': isOpen }"
          @click="toggleMobileNav"
        >
          <SvgIcon
            :image="menu"
            class="secondary-navigation__icon secondary-navigation__menu-open"
            no-icon-class
          />
          <SvgIcon
            :image="menuClose"
            class="secondary-navigation__icon secondary-navigation__menu-close"
            no-icon-class
          />
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import BookmarkIndicator from '../bookmark-indicator.vue';
import CartIndicator from '@/components/cart-indicator.vue';
import SearchButton from '@/components/search-button.vue';
import menu from '@/assets/images/navigation/nav-menu.svg';
import menuClose from '@/assets/images/menu-close.svg';

const SCRIPT_TAG_ID = 'nexusScriptTag';

export default {
  name: 'SecondaryNavigation',
  components: {
    BookmarkIndicator,
    CartIndicator,
    SearchButton,
  },
  emits: ['toggle-mobile-nav'],
  data() {
    return {
      menu,
      menuClose,
      isOpen: false,
    };
  },
  mounted() {
    if (process.client && /^#suche/.test(document.location.hash)) {
      this.openSearch();
    }
  },
  methods: {
    toggleMobileNav() {
      this.isOpen = !this.isOpen;
      this.$emit('toggle-mobile-nav', this.isOpen);
    },
    // Copied from td-smarthome/components/secondary-navigation.vue
    loadNexusSearch() {
      if (!document.getElementById(SCRIPT_TAG_ID)) {
        const nexusScript = document.createElement('script');
        nexusScript.setAttribute('src', this.$config.public.nexusSearchScriptUrl);
        nexusScript.setAttribute('id', SCRIPT_TAG_ID);
        document.head.append(nexusScript);
      }
      if (!document.location.hash) {
        try {
          const savedSearchTerm = JSON.parse(
            decodeURIComponent(document.cookie.match(/(^| )nexus-pk-coin=([^;]+)/)[2])
          ).q;
          document.location.hash = `suche?${encodeURIComponent(savedSearchTerm)}`;
        } catch (e) {
          document.location.hash = 'suche';
        }
      }
    },
    // Copied from td-smarthome/components/secondary-navigation.vue
    openSearch() {
      this.loadNexusSearch();

      window?.scrollTo({ top: 0, left: 0 });
    },
  },
  watch: {
    $route() {
      this.isOpen = false;
    },
  },
};
</script>

<style lang="scss">
@use 'assets/base' as *;

.secondary-navigation {
  display: flex;
  align-items: center;
  height: 100%;

  &__list {
    list-style: none;
    display: flex;
    gap: $grid-base;
    height: 100%;

    @include mq($mq-sm) {
      gap: 1.5 * $grid-base;
    }
  }

  &__link-wrapper {
    height: 100%;
    position: relative;

    &:hover {
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        height: 4px;
        width: 100%;
        background-color: color(magenta, 1000);
      }
    }

    &:last-of-type {
      &:hover {
        &::after {
          display: none;
        }
      }

      .secondary-navigation__link {
        &:focus,
        &:hover,
        &--active {
          fill: var(--color-font);
          color: var(--color-font);
        }
      }
    }

    @include mq($mq-lg) {
      height: 70px;
    }
  }

  &__link {
    display: flex;
    height: 100%;
    color: var(--color-font-subtle);
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    border-radius: 0;
    font-size: 12px;
    line-height: 1;
    font-family: 'TeleNeo', Arial, sans-serif;
    font-weight: 700;
    padding: 0;

    @include mq($mq-sm) {
      flex-direction: row;
    }

    @include mq($mq-lg) {
      flex-direction: column;
    }

    &--active {
      fill: var(--color-primary);
      color: var(--color-primary);
    }

    &--bookmark {
      fill: none;
      stroke: var(--color-font-subtle);
    }

    &:focus,
    &:hover {
      background-color: transparent;
      fill: var(--color-primary);
      color: var(--color-primary);
      cursor: pointer;
    }

    &--bookmark:focus,
    &--bookmark:hover {
      fill: none;
      stroke: var(--color-primary);
    }
  }

  &__icon {
    width: 18px;
    margin: 0 6px;
    fill: currentColor;
    @include mq($mq-sm) {
      width: 24px;
    }
  }

  &__menu-close {
    height: $grid-base;
  }

  &__menu {
    @include mq($mq-lg) {
      display: none;
    }
  }

  .cart-indicator {
    .secondary-navigation__item-name {
      bottom: 3px;
    }
  }
}

body:not(.mobile-nav-open) {
  .secondary-navigation__menu-close {
    display: none;
  }
}

body.mobile-nav-open {
  .secondary-navigation__menu-open {
    display: none;
  }
}
</style>
