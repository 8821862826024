<template>
  <Flyout
    ref="flyout"
    class="flyout-container"
    :class="containerClass"
    v-bind="componentProps"
  >
    <template #default>
      <div v-html="flyoutState.content" />
      <template v-if="flyoutState.component">
        <component
          :is="flyoutState.component.is"
          v-bind="flyoutState.component.props"
          :close="closeFlyout"
        />
      </template>
    </template>
    <template #footer>
      <LinkButton
        v-for="(button, key) in flyoutState.buttons"
        :key="key"
        v-bind="getFilteredProps(button)"
        @click.native="() => click(button)"
      >
        {{ button.name }}
      </LinkButton>
    </template>
  </Flyout>
</template>

<script setup lang="ts">
import { onUnmounted, useRoute } from '#imports';
import { computed, watch } from 'vue';
import { Flyout, LinkButton } from '@i22-td-smarthome/component-library';
import { flyoutState, flyout, closeFlyout } from '~/composables/flyout';
import type { ModalButton } from '~/composables/modal-types';

const props = defineProps({
  withNav: {
    type: Boolean,
  },
});

const componentProps = computed(() => ({
  success: flyoutState.success,
  warning: flyoutState.warning,
  error: flyoutState.error,
  closeOnBackdrop: flyoutState.closeOnBackdrop,
  headingText: flyoutState.heading,
}));

const getFilteredProps = (btnProps: ModalButton) => {
  const { click, name, ...passThroughProps }: Partial<ModalButton> = btnProps;
  if (!passThroughProps.tag) {
    passThroughProps.tag = 'button';
    passThroughProps.type = 'button';
  }
  return passThroughProps;
};

const click = async (button: ModalButton) => {
  if (typeof button.click === 'function') {
    await button.click(closeFlyout, flyout);
  } else {
    closeFlyout();
  }
};

const route = useRoute();

const unwatch = watch(
  route,
  () => {
    closeFlyout();
  },
  { deep: true }
);

onUnmounted(() => {
  unwatch();
});

const containerClass = computed(() => (props.withNav ? 'flyout-container--with-nav' : ''));
</script>

<style lang="scss" scoped>
@use 'assets/base' as *;

.flyout-container {
  --top-mobile: 0;
  --top-deskop: 0;

  &--with-nav {
    --top-mobile: 60px;
    --top-deskop: 100px;

    @include mq($mq-sm) {
      --top-mobile: 64px;
    }
  }
}
</style>
