<template>
  <main class="error">
    <client-only>
      <GridContainer>
        <div v-if="productSlug">
          <div class="error__header-container">
            <div class="error__header">
              <Heading
                align="center"
                spaces-top
              >
                Entschuldigung
              </Heading>
              <div class="text--center error__bubble">
                <svg
                  class="error__bubble-shape"
                  preserveAspectRatio="xMidYMid slice"
                  viewBox="0 0 174 101"
                  xmlns="http://www.w3.org/2000/svg"
                  xml:space="preserve"
                  style="
                    fill-rule: evenodd;
                    clip-rule: evenodd;
                    stroke-linejoin: round;
                    stroke-miterlimit: 2;
                    overflow: visible;
                  "
                >
                  <path
                    d="M15.023,164.917c-59.439,2.77 -81.626,-64.119 -51.41,-96.834c-15.152,-9.535 -25.983,-25.572 -28.202,-44.7c-3.883,-33.56 20.173,-63.914 53.746,-67.792c88.079,-10.205 142.847,-11.809 163.517,-9.885c37.514,3.499 56.14,30.004 57.658,58.521c6.043,0.933 11.532,2.42 16.728,4.577c25.341,10.468 40.901,35.632 38.741,62.574c-3.65,45.544 -50.273,62.66 -134.293,79.28c-47.791,9.477 -91.932,13.121 -116.485,14.259"
                    style="fill: #e20074"
                  />
                </svg>
                <div class="error__bubble-content">Leider<br />ausverkauft!</div>
              </div>
            </div>
          </div>
          <client-only>
            <ProductNotAvailable :product-slug="productSlug" />
          </client-only>
        </div>
        <div
          v-else
          class="error__global"
        >
          <div class="text--center">
            <ImageElement
              :src="image"
              alt="Entschuldigung"
            />

            <Heading
              spaces-top
              align="center"
            >
              {{ heading }}
            </Heading>

            <Paragraph
              align="center"
              v-html="content"
            />
          </div>

          <button
            v-if="error.message === 'Sample-Server-Error'"
            @click="triggerError()"
          >
            Trigger Error
          </button>

          <template v-if="isDev">
            <p>
              Message:<br /><strong>{{ error.message }}</strong>
            </p>

            <p v-if="parsedData?.url">
              Url:<br /><strong>{{ parsedData.url }}</strong>
            </p>

            <span>Stack:</span><br />
            <div
              style="overflow: auto; font-size: 0.8rem"
              v-html="parsedData?.stack || error?.stack"
            />
          </template>
        </div>
      </GridContainer>
    </client-only>
  </main>
</template>

<script>
import { defineAsyncComponent } from '#imports';
import {
  GridContainer,
  Heading,
  ImageElement,
  Paragraph,
} from '@i22-td-smarthome/component-library';
import PageHeader from '@/mixins/page-header';
import { captureException } from '~/lib/sentry';

export default {
  name: 'ErrorPage',
  components: {
    ProductNotAvailable: defineAsyncComponent(() =>
      import('@/components/product-not-available.vue')
    ),
    GridContainer,
    Heading,
    ImageElement,
    Paragraph,
  },
  mixins: [PageHeader],
  props: {
    error: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      isDev: !this.$config.public.mode.isProduction,
    };
  },
  computed: {
    productSlug() {
      if (!this.error?.data) return undefined;
      if (this.error.data?.productSlug) return this.error.data?.productSlug;
      try {
        return JSON.parse(this.error.data)?.productSlug;
      } catch (_e) {}
      return undefined;
    },
    errorStatus404() {
      return this.error.statusCode?.toString() === '404';
    },
    title() {
      return 'Fehler';
    },
    isIndexed() {
      return false;
    },
    heading() {
      if (this.errorStatus404) return 'Entschuldigung';
      return 'shop.telekom.de ist nicht erreichbar';
    },
    image() {
      if (this.errorStatus404)
        return '/content/content-images/error/2c71ef9aee89b85b64682f32c15b4615/icon-404.png';
      return '/content/content-images/error/c8d9690adc45f0ed51753da7ea62569b/icon-500.png';
    },
    content() {
      if (this.errorStatus404)
        return 'Hier haben wir uns wohl in den Einstellungen vertan – oder Du hast eine Seite aufgerufen, die nicht mehr existiert. Während wir im Hintergrund nach einem möglichen Fehler suchen, schaue doch einfach auf der <a href="/" class="link" data-tealium-rel="content.text.startseite">Startseite</a> vorbei und lasse Dich inspirieren – oder besuche direkt unseren <a href="/smartphones-und-handys" class="link"  data-tealium-rel="content.text.shop">Shop</a>.';
      return 'Wir wurden automatisch über den Fehler benachrichtigt. Bitte versuche, die Seite neu zu laden. Sollte dies nicht zum Erfolg führen, probiere es bitte in wenigen Minuten erneut.';
    },
    parsedData() {
      try {
        return JSON.parse(this.error?.data || '');
      } catch {
        return undefined;
      }
    },
  },
  mounted() {
    // Redirect the user to the Maintenance page. This step is done, to preserve the browser-history.
    if (this.error?.redirectToMaintenance) {
      this.$router.push('/maintenance');
    }
  },
  methods: {
    triggerError() {
      captureException(new Error('Sample-Client-Error'));
    },
  },
};
</script>

<style scoped lang="scss">
@use 'assets/base' as *;

.error {
  margin-top: $grid-base * 2;
  margin-bottom: $grid-base * 2;

  @include mq($mq-sm) {
    margin-top: $grid-base * 8;
    margin-bottom: $grid-base * 8;
  }

  &__global {
    width: 100%;
    max-width: $mq-md * 0.5;
    margin-inline: auto;
  }

  &__header-container {
    padding-top: $grid-base * 12;
    text-align: center;

    @include mq($mq-sm) {
      padding-top: $grid-base * 10;
    }

    @include mq($mq-md) {
      padding-top: $grid-base * 6;
    }
  }

  &__header {
    display: inline-block;
    position: relative;
  }

  &__bubble {
    position: absolute;
    left: 30%;
    top: -80%;
    color: var(--color-secondary);

    @include mq($mq-md) {
      left: 120%;
      top: 20%;
    }

    &-shape {
      overflow: visible;
      position: absolute;
      left: 0;
    }

    &-content {
      position: relative;
      font-weight: 700;
      @include font-size(24px);
      margin-top: 8%;
    }
  }
}
</style>
