export function sanitizeTerm(rawTerm) {
  let searchTerm = rawTerm.trim();
  searchTerm = searchTerm.replace(/[^-+a-zA-Z0-9 äüößÄÜÖ]/g, '');
  return searchTerm;
}

const ALLOWED_SECTIONS = ['alle', 'shop', 'möglichkeiten', 'hilfe', 'community'];

export function urlifyTermAndSection(rawTerm, rawSection) {
  let section = rawSection.toLowerCase();
  if (!ALLOWED_SECTIONS.includes(section)) {
    section = 'alle';
  }
  const term = sanitizeTerm(rawTerm);
  return {
    name: 'search',
    params: { section },
    query: { term },
  };
}
