import { merge } from 'lodash-es';
import Url from 'url';
import type { MappedResponseType } from 'ofetch/dist';
import type { Cart } from '@/lib/types/cartdata';
import type { RedeemedCouponResponse } from '@/store/coupon';
import { useRuntimeConfig } from '#imports';

type ErrorItem = {
  count?: number;
  error: string | string[];
  value?: string;
};

type QuantityError = {
  count: number;
  error: string;
  value: number;
};

export type BackendError<
  T extends string | number | symbol = string,
  V = Record<string, string | any>
> = Record<T, V[]>;

export type BackendResponse = {
  order?: Cart;
  errors?: BackendError<'articleNumber' | 'stock' | 'quantity' | string, ErrorItem | QuantityError>;
};

export type BackendCouponInfoResponse = {
  couponVoucher: RedeemedCouponResponse;
  errors?: BackendError<'redeemedCoupon', 'no_valid_articles' | 'invalid' | string>;
};

const backendApiUrl = '/api/shop';

// See: https://wiki.i22.de/display/TDSMARTHOME/Shop+Api+Calls
export const endpoints = {
  order: `${backendApiUrl}/order`,
  confirmation: `${backendApiUrl}/confirmation`,
  coupon: `${backendApiUrl}/coupon`,
  getCoupon: (value: string) => `${backendApiUrl}/coupon?redeemed_coupon=${value}`,
  items: `${backendApiUrl}/items`,
  availabilitySubscriptions: `${backendApiUrl}/availability_subscriptions`,
  paypal_express: `${backendApiUrl}/paypal_express`,
  revertOrderToCart: `${backendApiUrl}/revert_order_to_cart`,

  magentaCouponsConfirm: `${backendApiUrl}/magenta_coupons/confirm`,
  magentaCoupons: `${backendApiUrl}/magenta_coupons/`,

  dcbVouchers: `${backendApiUrl}/dcb_vouchers/`,
  dcbVouchersDOI: `${backendApiUrl}/dcb_vouchers/confirm`,
  shareCart: `${backendApiUrl}/share_carts/confirm`,
};

function addAfterPaymentUrls(
  orderParams: Record<string, unknown> | undefined
): Record<string, unknown> {
  const config = useRuntimeConfig();
  const { baseUrl } = config.public;
  // !!! On production the urls are hardcoded in BE !!!

  // We explicitly list the callbacks that the ShopApi Backend will pass to Brodos
  // and which in turn will be called by the browser after the payment process
  // has reached certain checkpoints

  const afterPaymentUrls = {
    brodosSuccessUrl: Url.resolve(baseUrl, '/api/brodos-response/success'),
    brodosFailureUrl: Url.resolve(baseUrl, '/api/brodos-response/failure'),
    novalnetSuccessUrl: Url.resolve(baseUrl, '/api/brodos-response/success'),
    novalnetFailureUrl: Url.resolve(baseUrl, '/api/brodos-response/failure'),
    confirmationUrl: Url.resolve(baseUrl, '/shop/bestaetigung'),
    telekomPaymentInformationUrl: Url.resolve(baseUrl, '/shop/telekom-bezahlung-information'),
    notifyUrl: Url.resolve(baseUrl, '/api/computop-response/notify'),
    dimocoNotifyUrl: Url.resolve(baseUrl, '/api/dimoco-response/notify'),
    successUrlTelekom: Url.resolve(baseUrl, '/shop/telekom-zahlungsverifizierung'),
    successUrlBrodos: Url.resolve(baseUrl, '/shop/brodos-zahlungsverifizierung'),
    successUrl: Url.resolve(baseUrl, '/shop/zahlungsverifizierung'),
    failureUrl: Url.resolve(baseUrl, '/shop/fehler'),
  };

  return merge({}, orderParams, afterPaymentUrls);
}

const apiCalls = {
  availabilitySubscriptions(subscription: any): Promise<MappedResponseType<any>> {
    return $shopApi(endpoints.availabilitySubscriptions, {
      method: 'POST',
      body: { subscription },
    });
  },
  submitOrder(orderParams: Record<string, unknown>): Promise<MappedResponseType<'json', any>> {
    const paramsWithAfterPaymentUrls = addAfterPaymentUrls(orderParams);
    return $shopApi(endpoints.order, {
      method: 'POST',
      body: { order: paramsWithAfterPaymentUrls },
    });
  },
  submitPaypalExpress(
    orderParams: Record<string, unknown>
  ): Promise<MappedResponseType<'json', any>> {
    const paramsWithAfterPaymentUrls = addAfterPaymentUrls(orderParams);
    return $shopApi(endpoints.paypal_express, {
      method: 'PATCH',
      body: { order: paramsWithAfterPaymentUrls },
    });
  },
  confirmOrder(): Promise<MappedResponseType<'json', any>> {
    return $shopApi(endpoints.confirmation);
  },
  deleteOrder(): Promise<MappedResponseType<'json', any>> {
    return $shopApi(endpoints.order, { method: 'DELETE' });
  },
  getCurrentCart(showLoadingSpinner = true): Promise<MappedResponseType<'json', BackendResponse>> {
    return $shopApi(endpoints.order, {
      loadingSpinner: showLoadingSpinner,
    });
  },
  items(
    items: { quantity?: number; articleNumber: string }[]
  ): Promise<MappedResponseType<'json', BackendResponse>> {
    return $shopApi(endpoints.items, { method: 'POST', body: { items } });
  },
  addCouponToCart({
    redeemedCoupon,
  }: {
    redeemedCoupon: string;
    // !This does return the normal Cart Response and NOT the CouponResponse!
  }): Promise<MappedResponseType<'json', BackendResponse>> {
    return $shopApi(endpoints.coupon, { method: 'PATCH', body: { order: { redeemedCoupon } } });
  },
  deleteCoupon(): Promise<MappedResponseType<'json', any>> {
    return $shopApi(endpoints.coupon, { method: 'DELETE' });
  },
  getCouponInfo(
    couponValue: string
  ): Promise<MappedResponseType<'json', BackendCouponInfoResponse>> {
    return $shopApi(endpoints.getCoupon(couponValue));
  },
  updateCustomerData(order: Partial<Cart>): Promise<MappedResponseType<'json', any>> {
    return $shopApi(endpoints.order, { method: 'PATCH', body: { order } });
  },
  revertOrderToCart(): Promise<MappedResponseType<'json', any>> {
    return $shopApi(endpoints.revertOrderToCart, {});
  },
  dcbVouchers(
    email: string,
    phoneNumber: string
  ): Promise<MappedResponseType<'json', { status?: 'verified'; error?: string }>> {
    const config = useRuntimeConfig();
    const { baseUrl } = config.public;
    return $shopApi(endpoints.dcbVouchers, {
      method: 'POST',
      body: {
        email,
        phoneNumber,
        frontend_url: `${baseUrl}/handyrechnung-aktion`,
      },
    });
  },
  dcbVouchersDOI(
    doubleOptinToken: string
  ): Promise<MappedResponseType<'json', { status?: 'verified'; error?: string }>> {
    return $shopApi(endpoints.dcbVouchersDOI, {
      method: 'POST',
      body: { double_optin_token: doubleOptinToken },
    });
  },
  shareCart(
    email: string
  ): Promise<MappedResponseType<'json', { status: 'Cart send.'; error?: any }>> {
    const config = useRuntimeConfig();
    const { baseUrl } = config.public;
    return $shopApi(endpoints.shareCart, {
      method: 'POST',
      body: { email, shop_url: `${baseUrl}/shop/warenkorb` },
    });
  },
};

export default apiCalls;
