import { useNuxtApp, defineNuxtRouteMiddleware, navigateTo } from '#imports';
import { H3Event } from 'h3';
import { isEmpty } from 'lodash-es';

// Nuxt Middleware Handler
export default defineNuxtRouteMiddleware(async (to, from) => {
  // Do nothing if path starts with /api
  if (to.path.startsWith('/api')) return;

  const { $redirects, ssrContext, $config, provide } = useNuxtApp();

  // On CSR we have to load the redirects and provide them!
  let redirects = $redirects;
  if (!$redirects && (import.meta.client || process.client)) {
    try {
      const host =
        $config.cloudFront.redirectsEnabled && $config.app.cdnURL ? $config.app.cdnURL : '';

      redirects = await $shopApi(`${host}/api/redirects`, {
        loadingSpinner: false,
        noSession: true,
      });
      provide('redirects', redirects);
    } catch (e) {
      console.error(e);
    }
  }

  if (!redirects) return;

  const redirectForPath = redirects[to.path];

  if (redirectForPath) {
    // Redirect to new url

    let queryString = '';
    if (!isEmpty(to.query) && !redirectForPath.to.includes('?')) {
      const params = new URLSearchParams(to.query as Record<string, string>);
      queryString = `?${params.toString()}`;
    }

    const { nuxt2Context } = useNuxtApp();
    // eslint-disable-next-line consistent-return
    return nuxt2Context.redirect(
      redirectForPath.isPermanent ? 301 : 302,
      redirectForPath.to + queryString
    );

    // This is for nuxt3
    /*
    // Set H3 Event, if not already set.
    if (!ssrContext?.event && ssrContext?.req) {
      ssrContext.event = new H3Event(ssrContext.req, ssrContext.res);
    }

    // enable when we use nuxt3!
    // On server-side we have to use the h3 event directly (https://nuxt.com/docs/api/utils/navigate-to)
    if (process.server && ssrContext?.event) {
      await sendRedirect(
        ssrContext.event,
        redirectForPath.to + queryString,
        redirectForPath.isPermanent ? 301 : 302
      );
    }

    return navigateTo(redirectForPath.to + queryString, {
      redirectCode: redirectForPath.isPermanent ? 301 : 302,
      external: redirectForPath.to.includes('://'), // enable external redirects.
    }); */
  }
});
