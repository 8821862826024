<template>
  <Modal
    ref="modal"
    v-bind="componentProps"
  >
    <template #default>
      <div v-html="modalState.content" />
      <template v-if="modalState.component">
        <component
          :is="modalState.component.is"
          v-bind="modalState.component.props"
          :close="closeModal"
        />
      </template>
    </template>
    <template #footer>
      <LinkButton
        v-for="(button, key) in modalState.buttons"
        :key="key"
        v-bind="getFilteredProps(button)"
        @click.native="() => click(button)"
      >
        {{ button.name }}
      </LinkButton>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { useRoute, onUnmounted, watch } from '#imports';
import { computed } from 'vue';
import { closeModal, modal, modalState } from '@/composables/modal';

import { Modal, LinkButton } from '@i22-td-smarthome/component-library';
import type { ModalButton } from '~/composables/modal-types';

const componentProps = computed(() => ({
  closeOnBackdrop: modalState.closeOnBackdrop,
  headingText: modalState.heading,
  customClass: modalState.className,
  noMaxWidth: modalState.noMaxWidth,
}));

const getFilteredProps = (props: ModalButton) => {
  const { click, name, ...passThroughProps }: Partial<ModalButton> = props;
  if (!passThroughProps.tag) {
    passThroughProps.tag = 'button';
    passThroughProps.type = 'button';
  }
  return passThroughProps;
};

const click = async (button: ModalButton) => {
  if (typeof button.click === 'function') {
    await button.click(closeModal, modal);
  } else {
    closeModal();
  }
};

const route = useRoute();

const unwatch = watch(
  route,
  () => {
    closeModal();
  },
  { deep: true }
);

onUnmounted(() => {
  unwatch();
});
</script>

<style scoped lang="scss"></style>
