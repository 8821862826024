<template>
  <div class="legal-modal-button">
    <button
      ref="button"
      type="button"
      class="legal-modal-button__button btn btn--footnote"
      :class="{ 'legal-modal-button__button--info': variant === 'info' }"
      aria-label="Fußnote"
      @click.stop="open"
    >
      <SvgIcon
        v-if="variant === 'info'"
        :image="infoIconCircle"
        class="legal-modal-button__info-icon"
      />
      <span v-else>*</span>
    </button>
    <Modal
      ref="modal"
      close-on-backdrop
      @close="$refs.button.focus()"
    >
      <template v-if="hasTexts">
        <div
          v-for="(text, index) in texts"
          :key="index"
          class="legal-modal-button__text"
        >
          <div v-html="text"></div>
        </div>
      </template>
      <slot name="modal" />
    </Modal>
  </div>
</template>

<script>
import { Modal } from '@i22-td-smarthome/component-library';
import infoIconCircle from '@/assets/images/info-icon-circle.svg';

export default {
  name: 'LegalModalButton',
  components: {
    Modal,
  },
  props: {
    texts: {
      type: Array,
      default: () => [],
    },
    variant: {
      type: String,
      default: 'default',
      validator(value) {
        return ['default', 'info'].includes(value);
      },
    },
  },
  data() {
    return {
      infoIconCircle,
    };
  },
  computed: {
    hasTexts() {
      return this.texts && this.texts.length > 0;
    },
  },
  methods: {
    open(e) {
      this.$refs.modal.openDialog();
    },
  },
};
</script>

<style lang="scss" scoped>
@use 'assets/base' as *;

.legal-modal-button {
  display: inline-block;

  &__text {
    &:last-child p:last-of-type {
      margin-bottom: 0;
    }
  }

  &__button {
    &--info {
      border: none;
      padding-top: 0;
      border-radius: 50%;

      svg {
        width: 22px;
        height: 22px;
      }
    }
  }
}
</style>
