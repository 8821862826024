import { ref, reactive } from 'vue';
import type FlyoutType from '@i22-td-smarthome/component-library/dist/components/dialog/flyout.vue';
import type { ModalButton, DynamicComponent } from '~/composables/modal-types';

export type FlyoutOpenProps = {
  heading?: string | null;
  content?: string | null;
  component?: DynamicComponent | null;
  closeOnBackdrop?: boolean;
  buttons?: ModalButton[] | null;
  success?: boolean;
  error?: boolean;
  warning?: boolean;
};

export type FlyoutPlugin = {
  open: (props: FlyoutOpenProps) => void;
  close: () => void;
  info: (message: string, options: any) => void;
  el: InstanceType<any>;
};

export const flyoutState = reactive<FlyoutOpenProps>({
  heading: null,
  content: null,
  component: null,
  buttons: [],
  closeOnBackdrop: false,
  success: false,
  error: false,
  warning: false,
});

export const flyout = ref<typeof FlyoutType | undefined>(undefined);

export const resetFlyout = () => {
  flyoutState.heading = null;
  flyoutState.content = null;
  flyoutState.component = null;
  flyoutState.buttons = [];
  flyoutState.closeOnBackdrop = true;
  flyoutState.success = false;
  flyoutState.error = false;
  flyoutState.warning = false;
};

export const openFlyout = (props: FlyoutOpenProps) => {
  resetFlyout();
  Object.keys(props).forEach((key) => {
    // @ts-ignore
    flyoutState[key] = props[key];
  });
  flyout.value?.openDialog();
};

export const closeFlyout = () => {
  flyout.value?.closeDialog();
  resetFlyout();
};

// Compatibility to dialog.info.
export const info = (message: string, options: any = {}) => {
  openFlyout({
    content: message,
    success: options.type === 'success',
    error: options.type === 'error',
    warning: options.type === 'warning',
    buttons: [
      {
        autofocus: true,
        name: options.buttonText || 'OK',
        transparent: true,
        isWide: true,
      },
    ],
  });
};
