import { captureException, captureMessage } from '~/lib/sentry';

export const validate = <R, K extends keyof NonNullable<R>>(
  response: R | undefined | string | { errors: any[] },
  responseKey: K,
  contexts: Record<string, any>
): NonNullable<NonNullable<R>[K]> | undefined => {
  if (!response) return undefined;
  // server has a 500 and returns "internal server error" as body.
  if (typeof response === 'string' || typeof response === 'undefined') {
    captureMessage(`[Goliath]: ${response || 'Returned unexpected response'}`, {
      contexts,
      extra: response as any,
    });
    return undefined;
  }

  if (typeof response === 'object' && 'errors' in response) {
    if (Array.isArray(response.errors)) {
      response.errors.forEach((e: Error | any) => {
        const message = e?.message || 'Goliath Response Error';
        captureMessage(`[Goliath]: ${message}`, {
          contexts,
          extra: e,
        });
      });
    } else {
      // @ts-ignore
      captureMessage(response.errors, {
        contexts: {
          ...contexts,
          // @ts-ignore
          error: response?.errors,
        },
      });
    }
    return undefined;
  }

  if (response[responseKey]) {
    return response[responseKey] as NonNullable<NonNullable<R>[K]>;
  }
  const e = new Error(
    `No suitable response received from goliath. Missing response payload "${String(responseKey)}"`
  );
  captureException(e, {
    contexts: {
      ...contexts,
      // @ts-ignore
      response: JSON.stringify(response),
    },
  });
  return undefined;
};
