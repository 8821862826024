import type { GetterTree, ActionTree, MutationTree } from 'vuex';
import type { ListOffer } from '@/lib/goliath/offers';
import type { RootState } from '@/types/store-types';

// Use an object with the magic "__noSuchMethod__" function to catch all calls, when Local Storage is not available).
// @ts-ignore
const storage: Storage =
  typeof navigator !== 'undefined' &&
  navigator?.cookieEnabled &&
  typeof localStorage !== 'undefined' &&
  localStorage !== null
    ? localStorage
    : { __noSuchMethod__: () => {} };
const STORE_KEY = 'telekomLastSeenOffers';

export { STORE_KEY };

export interface LastSeenOffersState {
  offerArticleNumbers: string[];
  lastSeenFetchedOffers: ListOffer[];
  lastRemovedArticleNumber: undefined | string;
}

interface LastSeenOffersGettersTypes {
  lastSeenFetchedOffers: ListOffer[];
  lastSeenOffersFromStorage: string[];
  lastSeenOffersArticleNumbers: string[];
}

export type LastSeenOffersGetterTree = {
  [P in keyof LastSeenOffersGettersTypes]: LastSeenOffersGettersTypes[P];
};

export type LastSeenOffersGetters = {
  [P in keyof LastSeenOffersGettersTypes]: (
    state: LastSeenOffersState,
    getters: LastSeenOffersGetterTree
  ) => LastSeenOffersGettersTypes[P];
};

export const state = (): LastSeenOffersState => ({
  // A list of all article numbers that have been last seen
  offerArticleNumbers: [],
  // A list of the full offer objects used to display the last seen product tiles
  lastSeenFetchedOffers: [],

  lastRemovedArticleNumber: undefined,
});

export const mutations: MutationTree<LastSeenOffersState> = {
  INIT_LAST_SEEN_OFFERS(state, articleNumber) {
    const lastSeenOffers = state.offerArticleNumbers;

    // remove the item if it's in the array
    if (lastSeenOffers.includes(articleNumber)) {
      lastSeenOffers.splice(lastSeenOffers.indexOf(articleNumber), 1);
    }

    // add the item to the end of the array since it's the last seen item
    lastSeenOffers.push(articleNumber);

    // check length, if there are already enough items, remove first one
    if (lastSeenOffers.length >= 6) {
      state.lastRemovedArticleNumber = lastSeenOffers.shift();

      // remove it from lastSeenFetchedOffers
      if (
        state.lastRemovedArticleNumber === state.lastSeenFetchedOffers?.[0]?.product?.articleNumber
      ) {
        state.lastSeenFetchedOffers.shift();
      }
    }
    try {
      storage.setItem(STORE_KEY, JSON.stringify(lastSeenOffers));
    } catch (e) {
      // empty
    }
    state.offerArticleNumbers = lastSeenOffers;
  },
  SET_LAST_SEEN_ARTICLENUMBERS(state, offerArticleNumbers) {
    state.offerArticleNumbers = offerArticleNumbers || [];
  },
  ADD_LAST_SEEN_OFFERS(state, offers) {
    const articleNumbers = state.lastRemovedArticleNumber
      ? [state.lastRemovedArticleNumber, ...state.offerArticleNumbers]
      : state.offerArticleNumbers;
    state.lastSeenFetchedOffers = articleNumbers
      .map((articleNumber) =>
        [...state.lastSeenFetchedOffers, ...offers].find(
          (offer) => offer.product.articleNumber === articleNumber
        )
      )
      .filter(Boolean);
  },
};

export const actions: ActionTree<LastSeenOffersState, RootState> = {
  loadOffersFromLocalstorage(context) {
    const offerArticleNumbers = context.getters.lastSeenOffersFromStorage;
    context.commit('SET_LAST_SEEN_ARTICLENUMBERS', offerArticleNumbers);
  },
  async initLastSeenOffers(context, { articleNumber }) {
    await context.dispatch('loadOffersFromLocalstorage');
    context.commit('INIT_LAST_SEEN_OFFERS', articleNumber);
  },
  addLastSeenOffers(context, offers: ListOffer) {
    if (!Array.isArray(offers) || offers.length === 0) return;

    context.commit('ADD_LAST_SEEN_OFFERS', offers);
  },
};

export const getters: GetterTree<LastSeenOffersState, RootState> & LastSeenOffersGetters = {
  lastSeenFetchedOffers(state) {
    return state.lastSeenFetchedOffers || [];
  },
  lastSeenOffersFromStorage(state) {
    const lastSeenOffers = state.offerArticleNumbers;
    if (lastSeenOffers.length > 0) return lastSeenOffers;
    try {
      return JSON.parse(storage.getItem(STORE_KEY) as string) || [];
    } catch (e) {
      return [];
    }
  },
  lastSeenOffersArticleNumbers(state) {
    if (!state.offerArticleNumbers) return [];

    return state.lastRemovedArticleNumber
      ? [state.lastRemovedArticleNumber, ...state.offerArticleNumbers]
      : state.offerArticleNumbers;
  },
};
