import { defineNuxtPlugin, useRouter } from '#imports';
import * as Sentry from '@sentry/vue';

export default defineNuxtPlugin(async (nuxtApp) => {
  const config = nuxtApp.$config;
  Sentry.init({
    app: nuxtApp.vueApp,
    enabled: config.public.sentry.enabled,
    dsn: config.public.sentry.fakeDsn,
    integrations: [
      Sentry.browserTracingIntegration({ router: useRouter() }),
      Sentry.replayIntegration(),
    ],
    tunnel: '/api/sentry',
    environment: config.public.mode.name,

    tracePropagationTargets: ['localhost', config.public.baseUrl],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0,

    autoSessionTracking: false,
    sendClientReports: false,
    debug: false,
  });

  globalThis.$sentry = {
    captureException: Sentry.captureException,
    captureMessage: Sentry.captureMessage,
  };
  nuxtApp.provide('sentry', globalThis.$sentry);
});
