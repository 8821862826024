import type {
  OfferByArticleNumberQuery,
  OfferByArticleNumberQueryVariables,
} from '@/lib/goliath/goliath.types';

export default async (
  params: OfferByArticleNumberQueryVariables,
  progress = true
): Promise<OfferByArticleNumberQuery['offer'] | undefined> => {
  const data = await globalThis.$goliathApi<
    OfferByArticleNumberQueryVariables,
    OfferByArticleNumberQuery,
    'offer'
  >('offer-by-article-number', params, 'offer', progress);
  return data?.product ? data : undefined;
};
