import { readonly, ref } from 'vue';
import fetchOffers from '@/lib/goliath/offers';
import mergeFilterVariables from '@/lib/filter-params/merge-variables';
import Dialog from '@/helpers/dialog';
// eslint-disable-next-line import/no-cycle
import Tealium from '@/lib/tealium';
import type { ListQuery } from '~/lib/goliath/goliath.types';

const LOCAL_STORAGE_KEY = 'product_bookmarks';
const MAX_BOOKMARKS = 48;

type Bookmark = {
  articleNumber: string;
  price: number | undefined;
  name: string | undefined;
};

const productBookmarks = ref<Bookmark[]>([]);

// Bookmarking is only available when local storage is defined and cookies are enabled
const isBookmarkingAvailable =
  typeof navigator !== 'undefined' &&
  navigator.cookieEnabled &&
  typeof localStorage !== 'undefined' &&
  localStorage !== null;

const getProductBookmarks = (): Bookmark[] => {
  if (!isBookmarkingAvailable) return [];
  const item = localStorage.getItem(LOCAL_STORAGE_KEY);
  if (!item) return [];

  const bookmarks = JSON.parse(item);
  if (Array.isArray(bookmarks)) {
    return bookmarks;
  }
  console.error('Error reading bookmarks from local storage');
  return [];
};

type BookmarkState = 'error' | 'added' | 'removed';

const toggleBookmark = (articleNumber: string, name?: string, price?: number): BookmarkState => {
  if (!isBookmarkingAvailable) return 'error';
  const bookmarks = getProductBookmarks();
  let state: BookmarkState = 'added';
  let tealiumTag = 'hinzufuegen-zu-merkliste';
  let updatedProductBookmarks = [];

  const isProductInBookmarks =
    bookmarks.findIndex((product) => product.articleNumber === articleNumber) >= 0;

  if (isProductInBookmarks) {
    state = 'removed';
    tealiumTag = 'entfernen-von-merkliste';
    updatedProductBookmarks = bookmarks.filter(
      (product) => product.articleNumber !== articleNumber
    );
  } else if (bookmarks.length < MAX_BOOKMARKS) {
    updatedProductBookmarks = [...bookmarks, { articleNumber, price, name }];
  } else {
    Dialog.info(
      'Eintrag konnte nicht hinzugefügt werden. Maximale Anzahl von 48 Merklisten-Einträgen ist erreicht.',
      { type: 'error' }
    );
    return 'error';
  }

  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(updatedProductBookmarks));
  productBookmarks.value = updatedProductBookmarks;

  Tealium.link({
    wt_link_id: `content.button.${tealiumTag}.${articleNumber}`,
  });

  return state;
};

const isProductInBookmarks = (articleNumber: string) =>
  productBookmarks.value.findIndex((bookmark) => bookmark.articleNumber === articleNumber) >= 0;

const fetchBookmarkedOffers = async (newFilters: unknown): Promise<ListQuery['offers'] | null> => {
  if (productBookmarks.value.length === 0) return null;
  try {
    const params = {
      filter: {
        listed: true,
        articleNumbers: productBookmarks.value.map(({ articleNumber }) => articleNumber),
      },
      pagination: { size: 20, page: 1 },
      includeAggregations: true,
    };
    const filterParams = mergeFilterVariables(params, newFilters);

    return await fetchOffers(filterParams, false, true);
  } catch (e) {
    console.error(e);
  }
  return null;
};

export default function useProductBookmarks() {
  const bookmarks = getProductBookmarks();
  // set bookmarks only one.
  if (JSON.stringify(bookmarks) !== JSON.stringify(productBookmarks.value)) {
    productBookmarks.value = bookmarks;
  }

  return {
    productBookmarks: readonly(productBookmarks),
    fetchBookmarkedOffers,
    toggleBookmark,
    isBookmarkingAvailable,
    isProductInBookmarks,
  };
}
