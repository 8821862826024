<template>
  <component
    :is="imageComponent"
    v-if="imageComponent"
    :class="{ icon: !noIconClass }"
  />
</template>

<script lang="ts">
import type { CreateElement, VNode, PropType } from 'vue';

export interface SvgComponent {
  render: (h: CreateElement) => VNode;
}

export default {
  name: 'SvgIcon',
  props: {
    image: {
      type: Object as PropType<SvgComponent>,
      required: true,
    },
    noIconClass: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    // SVG is a Vue-Component
    imageComponent() {
      if (
        typeof this.image === 'object' &&
        this.image.render &&
        typeof this.image.render === 'function'
      ) {
        const image = this.image as SvgComponent;
        return {
          render: (h: CreateElement) => image.render.call(this, h),
        };
      }
      return undefined;
    },
  },
};
</script>
