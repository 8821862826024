import { compact, isUndefined, isNil } from 'lodash-es';
import Url from 'url';

function metaProp(name, content) {
  if (!content) {
    return undefined;
  }
  return {
    name,
    hid: name,
    content,
  };
}

export default {
  computed: {
    isIndexed() {
      return true;
    },
    canonicalPath() {
      return this.$route.path;
    },
    canonicalUrl() {
      if (!this.isIndexed) {
        return undefined;
      }
      if (!isNil(this.selfCanonical)) {
        return this.selfCanonical;
      }
      return Url.resolve(this.$config.public.baseUrl, this.canonicalPath);
    },
    fullTitle() {
      if (!this.title) return undefined;
      return `${this.title} | Telekom Zubehörshop`;
    },
    title() {
      return this.pageMeta?.title || undefined;
    },
    description() {
      return this.pageMeta?.description || undefined;
    },
    ogType() {
      return this.pageMeta?.og?.type || 'website';
    },
    ogImagePath() {
      return this.pageMeta?.og?.image || '';
    },
    ogImageUrl() {
      if (!this.ogImagePath) return '';
      try {
        return new URL(this.ogImagePath, this.$config.public.baseUrl).href;
      } catch (_e) {
        return '';
      }
    },
    ogDescription() {
      return (
        this.pageMeta?.og?.description ||
        this.description ||
        'Große Auswahl an Handys & Smartphones, Tablets und Zubehör. Geprüfte Sicherheit ✓ Schnelle Lieferzeiten ✓ Jetzt entdecken und bestellen!'
      );
    },
    nexusDescription() {
      return this.description;
    },
    robots() {
      // When this.isIndexed is not explicitly defined we consider the page as
      // indexable. You need to explicitly opt-out indexing by defining a
      // data entry or computed property in your own page component.
      const isIndexed = isUndefined(this.isIndexed) || this.isIndexed;
      return [isIndexed ? 'index' : 'noindex', 'follow'].join(', ');
    },
    meta() {
      return compact([
        metaProp('robots', this.robots),
        metaProp('description', this.description),
        metaProp('og:url', this.canonicalUrl),
        metaProp('og:title', this.fullTitle),
        metaProp('og:type', this.ogType),
        metaProp('og:image', this.ogImageUrl),
        metaProp('og:description', this.ogDescription),
        metaProp('sitemap:title', this.title),
        metaProp('nexus:title', this.title),
        metaProp('nexus:description', this.nexusDescription),
      ]);
    },
    prevPagePath() {
      return undefined;
    },
    prevPageUrl() {
      if (!this.prevPagePath) {
        return undefined;
      }
      return Url.resolve(this.$config.public.baseUrl, this.prevPagePath);
    },
    nextPagePath() {
      return undefined;
    },
    nextPageUrl() {
      if (!this.nextPagePath) {
        return undefined;
      }
      return Url.resolve(this.$config.public.baseUrl, this.nextPagePath);
    },
    link() {
      const links = [];
      if (this.canonicalUrl) {
        links.push({ rel: 'canonical', href: this.canonicalUrl });
      }
      if (this.prevPageUrl) {
        links.push({ rel: 'prev', href: this.prevPageUrl });
      }
      if (this.nextPageUrl) {
        links.push({ rel: 'next', href: this.nextPageUrl });
      }
      if (Array.isArray(this.customLinkRelImagePreloads)) {
        this.customLinkRelImagePreloads.forEach((el) => {
          links.push({ rel: 'preload', href: el, as: 'image' });
        });
      }
      return links;
    },
  },
  head() {
    return {
      title: this.fullTitle,
      meta: this.meta,
      link: this.link,
    };
  },
};
