import { defineNuxtPlugin } from '#imports';
import Vue from 'vue';
import NuxtJsonld from 'nuxt-jsonld';

Vue.use(NuxtJsonld);

export default defineNuxtPlugin((nuxtApp) => {
  // @ts-ignore
  if (typeof NuxtJsonld.default !== 'undefined') {
    // @ts-ignore
    nuxtApp.vueApp.use(NuxtJsonld.default);
  }
});
