import type { Context } from '@nuxt/types';
import { setConfig } from '@i22-td-smarthome/component-library';

// Set runtime env to component lib.
export default ({ app }: Context) => {
  setConfig({
    cloudFront: {
      ...app.$config.public.cloudFront,
      host: app.$config.app.cdnURL.replace(/^(https?:\/\/|\/\/)/, ''),
    },
    imageOptimizer: app.$img.getSizes,
  });
};
