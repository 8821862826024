import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _4dfad29e = () => interopDefault(import('../pages/a-good-company.vue' /* webpackChunkName: "pages/a-good-company" */))
const _4d3c1c59 = () => interopDefault(import('../pages/anker/index.vue' /* webpackChunkName: "pages/anker/index" */))
const _527feea5 = () => interopDefault(import('../pages/bitte-kommen-sie-spaeter-wieder.vue' /* webpackChunkName: "pages/bitte-kommen-sie-spaeter-wieder" */))
const _5cbed270 = () => interopDefault(import('../pages/displex.vue' /* webpackChunkName: "pages/displex" */))
const _6a267a43 = () => interopDefault(import('../pages/displex-fullcover.vue' /* webpackChunkName: "pages/displex-fullcover" */))
const _a800cffa = () => interopDefault(import('../pages/displex-privacy.vue' /* webpackChunkName: "pages/displex-privacy" */))
const _27d4808a = () => interopDefault(import('../pages/displex-tablet.vue' /* webpackChunkName: "pages/displex-tablet" */))
const _1b90d081 = () => interopDefault(import('../pages/google/index.vue' /* webpackChunkName: "pages/google/index" */))
const _17ffee6e = () => interopDefault(import('../pages/handyrechnung-aktion.vue' /* webpackChunkName: "pages/handyrechnung-aktion" */))
const _456bab6c = () => interopDefault(import('../pages/handyvergleich.vue' /* webpackChunkName: "pages/handyvergleich" */))
const _0266e72e = () => interopDefault(import('../pages/infos-zum-online-kauf.vue' /* webpackChunkName: "pages/infos-zum-online-kauf" */))
const _1be8d32b = () => interopDefault(import('../pages/kontakt.vue' /* webpackChunkName: "pages/kontakt" */))
const _5f9cb7c4 = () => interopDefault(import('../pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _3a7ffebe = () => interopDefault(import('../pages/merkliste.vue' /* webpackChunkName: "pages/merkliste" */))
const _662abe5d = () => interopDefault(import('../pages/panzerglass.vue' /* webpackChunkName: "pages/panzerglass" */))
const _148ddeae = () => interopDefault(import('../pages/preisvorteile-sichern.vue' /* webpackChunkName: "pages/preisvorteile-sichern" */))
const _cd5ca44c = () => interopDefault(import('../pages/ratenzahlung.vue' /* webpackChunkName: "pages/ratenzahlung" */))
const _fd6dfbde = () => interopDefault(import('../pages/redirecting.vue' /* webpackChunkName: "pages/redirecting" */))
const _648705c8 = () => interopDefault(import('../pages/samsung/index.vue' /* webpackChunkName: "pages/samsung/index" */))
const _0ff39fee = () => interopDefault(import('../pages/samsung-cashback-aktion.vue' /* webpackChunkName: "pages/samsung-cashback-aktion" */))
const _10073b58 = () => interopDefault(import('../pages/smart-home-entdecken.vue' /* webpackChunkName: "pages/smart-home-entdecken" */))
const _43230046 = () => interopDefault(import('../pages/stabiles-internet.vue' /* webpackChunkName: "pages/stabiles-internet" */))
const _718a89ee = () => interopDefault(import('../pages/themenwelten.vue' /* webpackChunkName: "pages/themenwelten" */))
const _56e82792 = () => interopDefault(import('../pages/valentins-angebote.vue' /* webpackChunkName: "pages/valentins-angebote" */))
const _5540b400 = () => interopDefault(import('../pages/xiaomi.vue' /* webpackChunkName: "pages/xiaomi" */))
const _75f7ff01 = () => interopDefault(import('../pages/xiaomi-smartphones.vue' /* webpackChunkName: "pages/xiaomi-smartphones" */))
const _0d46ad8f = () => interopDefault(import('../pages/xiaomi-zubehoer.vue' /* webpackChunkName: "pages/xiaomi-zubehoer" */))
const _3d1b9373 = () => interopDefault(import('../pages/zahlung-per-handyrechnung.vue' /* webpackChunkName: "pages/zahlung-per-handyrechnung" */))
const _23008f14 = () => interopDefault(import('../pages/anker/product-data.ts' /* webpackChunkName: "pages/anker/product-data" */))
const _07f78406 = () => interopDefault(import('../pages/google/product-data.js' /* webpackChunkName: "pages/google/product-data" */))
const _3fe897f2 = () => interopDefault(import('../pages/hilfe/lieferstatus-und-retoure.vue' /* webpackChunkName: "pages/hilfe/lieferstatus-und-retoure" */))
const _d5d91bf0 = () => interopDefault(import('../pages/magenta-black-days/page-data.js' /* webpackChunkName: "pages/magenta-black-days/page-data" */))
const _d2e72d7a = () => interopDefault(import('../pages/samsung/product-data.ts' /* webpackChunkName: "pages/samsung/product-data" */))
const _58bde668 = () => interopDefault(import('../pages/shop/bestaetigung.vue' /* webpackChunkName: "pages/shop/bestaetigung" */))
const _063e034e = () => interopDefault(import('../pages/shop/brodos-zahlungsverifizierung.vue' /* webpackChunkName: "pages/shop/brodos-zahlungsverifizierung" */))
const _66d3cd98 = () => interopDefault(import('../pages/shop/fehler.vue' /* webpackChunkName: "pages/shop/fehler" */))
const _1e1ee4d9 = () => interopDefault(import('../pages/shop/kundenformular.vue' /* webpackChunkName: "pages/shop/kundenformular" */))
const _f14eb6c8 = () => interopDefault(import('../pages/shop/kundenformular/index.vue' /* webpackChunkName: "pages/shop/kundenformular/index" */))
const _7c59dc0c = () => interopDefault(import('../pages/shop/kundenformular/persoenliche-daten.vue' /* webpackChunkName: "pages/shop/kundenformular/persoenliche-daten" */))
const _cfbb3006 = () => interopDefault(import('../pages/shop/kundenformular/zahlungsart.vue' /* webpackChunkName: "pages/shop/kundenformular/zahlungsart" */))
const _5aa18ed6 = () => interopDefault(import('../pages/shop/telekom-bestaetigung.vue' /* webpackChunkName: "pages/shop/telekom-bestaetigung" */))
const _355f77c6 = () => interopDefault(import('../pages/shop/telekom-bezahlung-information.vue' /* webpackChunkName: "pages/shop/telekom-bezahlung-information" */))
const _67baa9d7 = () => interopDefault(import('../pages/shop/telekom-zahlungsverifizierung.vue' /* webpackChunkName: "pages/shop/telekom-zahlungsverifizierung" */))
const _e091f0de = () => interopDefault(import('../pages/shop/warenkorb.vue' /* webpackChunkName: "pages/shop/warenkorb" */))
const _5f1a92f6 = () => interopDefault(import('../pages/shop/zahlungsverifizierung.vue' /* webpackChunkName: "pages/shop/zahlungsverifizierung" */))
const _12810666 = () => interopDefault(import('../pages/shop/zusammenfassung.vue' /* webpackChunkName: "pages/shop/zusammenfassung" */))
const _5a4b3b87 = () => interopDefault(import('../pages/weitere-informationen/agb-brodos.vue' /* webpackChunkName: "pages/weitere-informationen/agb-brodos" */))
const _521797b8 = () => interopDefault(import('../pages/weitere-informationen/sitemap.vue' /* webpackChunkName: "pages/weitere-informationen/sitemap" */))
const _6f5f2764 = () => interopDefault(import('../pages/weitere-informationen/ueber-brodos.vue' /* webpackChunkName: "pages/weitere-informationen/ueber-brodos" */))
const _36632fcc = () => interopDefault(import('../pages/weitere-informationen/ueber-qivicon.vue' /* webpackChunkName: "pages/weitere-informationen/ueber-qivicon" */))
const _79c2d6c6 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _417d8a84 = () => interopDefault(import('../pages/magenta-moments-2024/_slug.vue' /* webpackChunkName: "pages/magenta-moments-2024/_slug" */))
const _f4e4b032 = () => interopDefault(import('../special-pages/product-details.vue' /* webpackChunkName: "" */))
const _ffccdd50 = () => interopDefault(import('../special-pages/shop-list.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/a-good-company",
    component: _4dfad29e,
    name: "a-good-company"
  }, {
    path: "/anker",
    component: _4d3c1c59,
    name: "anker"
  }, {
    path: "/bitte-kommen-sie-spaeter-wieder",
    component: _527feea5,
    name: "bitte-kommen-sie-spaeter-wieder"
  }, {
    path: "/displex",
    component: _5cbed270,
    name: "displex"
  }, {
    path: "/displex-fullcover",
    component: _6a267a43,
    name: "displex-fullcover"
  }, {
    path: "/displex-privacy",
    component: _a800cffa,
    name: "displex-privacy"
  }, {
    path: "/displex-tablet",
    component: _27d4808a,
    name: "displex-tablet"
  }, {
    path: "/google",
    component: _1b90d081,
    name: "google"
  }, {
    path: "/handyrechnung-aktion",
    component: _17ffee6e,
    name: "handyrechnung-aktion"
  }, {
    path: "/handyvergleich",
    component: _456bab6c,
    name: "handyvergleich"
  }, {
    path: "/infos-zum-online-kauf",
    component: _0266e72e,
    name: "infos-zum-online-kauf"
  }, {
    path: "/kontakt",
    component: _1be8d32b,
    name: "kontakt"
  }, {
    path: "/maintenance",
    component: _5f9cb7c4,
    name: "maintenance"
  }, {
    path: "/merkliste",
    component: _3a7ffebe,
    name: "merkliste"
  }, {
    path: "/panzerglass",
    component: _662abe5d,
    name: "panzerglass"
  }, {
    path: "/preisvorteile-sichern",
    component: _148ddeae,
    name: "preisvorteile-sichern"
  }, {
    path: "/ratenzahlung",
    component: _cd5ca44c,
    name: "ratenzahlung"
  }, {
    path: "/redirecting",
    component: _fd6dfbde,
    name: "redirecting"
  }, {
    path: "/samsung",
    component: _648705c8,
    name: "samsung"
  }, {
    path: "/samsung-cashback-aktion",
    component: _0ff39fee,
    name: "samsung-cashback-aktion"
  }, {
    path: "/smart-home-entdecken",
    component: _10073b58,
    name: "smart-home-entdecken"
  }, {
    path: "/stabiles-internet",
    component: _43230046,
    name: "stabiles-internet"
  }, {
    path: "/themenwelten",
    component: _718a89ee,
    name: "themenwelten"
  }, {
    path: "/valentins-angebote",
    component: _56e82792,
    name: "valentins-angebote"
  }, {
    path: "/xiaomi",
    component: _5540b400,
    name: "xiaomi"
  }, {
    path: "/xiaomi-smartphones",
    component: _75f7ff01,
    name: "xiaomi-smartphones"
  }, {
    path: "/xiaomi-zubehoer",
    component: _0d46ad8f,
    name: "xiaomi-zubehoer"
  }, {
    path: "/zahlung-per-handyrechnung",
    component: _3d1b9373,
    name: "zahlung-per-handyrechnung"
  }, {
    path: "/anker/product-data",
    component: _23008f14,
    name: "anker-product-data"
  }, {
    path: "/google/product-data",
    component: _07f78406,
    name: "google-product-data"
  }, {
    path: "/hilfe/lieferstatus-und-retoure",
    component: _3fe897f2,
    name: "hilfe-lieferstatus-und-retoure"
  }, {
    path: "/magenta-black-days/page-data",
    component: _d5d91bf0,
    name: "magenta-black-days-page-data"
  }, {
    path: "/samsung/product-data",
    component: _d2e72d7a,
    name: "samsung-product-data"
  }, {
    path: "/shop/bestaetigung",
    component: _58bde668,
    name: "shop-bestaetigung"
  }, {
    path: "/shop/brodos-zahlungsverifizierung",
    component: _063e034e,
    name: "shop-brodos-zahlungsverifizierung"
  }, {
    path: "/shop/fehler",
    component: _66d3cd98,
    name: "shop-fehler"
  }, {
    path: "/shop/kundenformular",
    component: _1e1ee4d9,
    children: [{
      path: "",
      component: _f14eb6c8,
      name: "shop-kundenformular"
    }, {
      path: "persoenliche-daten",
      component: _7c59dc0c,
      name: "shop-kundenformular-persoenliche-daten"
    }, {
      path: "zahlungsart",
      component: _cfbb3006,
      name: "shop-kundenformular-zahlungsart"
    }]
  }, {
    path: "/shop/telekom-bestaetigung",
    component: _5aa18ed6,
    name: "shop-telekom-bestaetigung"
  }, {
    path: "/shop/telekom-bezahlung-information",
    component: _355f77c6,
    name: "shop-telekom-bezahlung-information"
  }, {
    path: "/shop/telekom-zahlungsverifizierung",
    component: _67baa9d7,
    name: "shop-telekom-zahlungsverifizierung"
  }, {
    path: "/shop/warenkorb",
    component: _e091f0de,
    name: "shop-warenkorb"
  }, {
    path: "/shop/zahlungsverifizierung",
    component: _5f1a92f6,
    name: "shop-zahlungsverifizierung"
  }, {
    path: "/shop/zusammenfassung",
    component: _12810666,
    name: "shop-zusammenfassung"
  }, {
    path: "/weitere-informationen/agb-brodos",
    component: _5a4b3b87,
    name: "weitere-informationen-agb-brodos"
  }, {
    path: "/weitere-informationen/sitemap",
    component: _521797b8,
    name: "weitere-informationen-sitemap"
  }, {
    path: "/weitere-informationen/ueber-brodos",
    component: _6f5f2764,
    name: "weitere-informationen-ueber-brodos"
  }, {
    path: "/weitere-informationen/ueber-qivicon",
    component: _36632fcc,
    name: "weitere-informationen-ueber-qivicon"
  }, {
    path: "/",
    component: _79c2d6c6,
    name: "index"
  }, {
    path: "/magenta-moments-2024/:slug?",
    component: _417d8a84,
    name: "magenta-moments-2024-slug"
  }, {
    path: "/geraete/:slug?",
    component: _f4e4b032,
    name: "ProductDetails"
  }, {
    path: "/:config+",
    component: _ffccdd50,
    name: "ShopList"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
