import { mapActions, mapGetters } from 'vuex';
import { debounce } from 'lodash-es';

const WIDTH_DEBOUNCE_MS = 200;

function hasWindow() {
  return typeof window !== 'undefined';
}

export default {
  data() {
    return {
      resizeHandler: null,
      hasVerticalScrollbar: false,
    };
  },
  created() {
    if (!hasWindow()) return;
    this.resizeHandler = debounce(() => {
      this.setViewportSize();
    }, WIDTH_DEBOUNCE_MS);
    this.setViewportSize();
    window.addEventListener('resize', this.resizeHandler);
  },
  destroyed() {
    if (!hasWindow()) return;
    window.removeEventListener('resize', this.resizeHandler);
    this.resizeHandler = null;
  },
  computed: {
    ...mapGetters('backdrop', {
      backdropIsActive: 'isActive',
      backdropIsGloballyActive: 'isGloballyActive',
    }),
    ...mapGetters('downtimes', ['maintenanceDowntime']),
    isPageWithDowntime() {
      return [
        'aktionsangebote',
        'shop-warenkorb',
        'shop-kundenformular',
        'shop-zusammenfassung',
        'shop-list',
        'ShopList',
        'magenta-moments-2024',
        'ProductDetails',
        'magenta-tv-hardware',
        'preisvorteile-sichern',
      ].includes(this.$route?.name);
    },
    isMaintenancePage() {
      return this.$route?.name === 'maintenance';
    },
  },
  async mounted() {
    await this.checkForDowntime();
    // In all cart pages, a fresh cart is fetched. So we do not need this here.
    if (!this.$route.path?.startsWith('/shop/') && !this.maintenanceDowntime) {
      // skip in minimal mode
      if (this.$config.public?.performanceMode?.enabled) return;
      await this.initCart();
    }
  },
  watch: {
    async $route() {
      await this.checkForDowntime();
    },
    maintenanceDowntime: {
      async handler(value) {
        if (value && this.isPageWithDowntime && !this.isMaintenancePage) {
          await this.$router.push('/maintenance');
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('downtimes', ['fetchDowntimes']),
    ...mapActions('cart', ['initCart']),
    setViewportSize() {
      if (!hasWindow()) return;

      this.hasVerticalScrollbar = window.innerWidth > document.documentElement.clientWidth;
    },
    async checkForDowntime() {
      // We have own logic on maintenance page.
      if (this.isMaintenancePage) return;

      // Check for downtimes only on pages that are protected by it!
      if (!this.isPageWithDowntime) return;
      await this.fetchDowntimes();
    },
  },
  // head() {
  //   const htmlClasses = ['app'];
  //   if (this.backdropIsActive) {
  //     htmlClasses.push('app--has-backdrop');
  //   }
  //   if (this.backdropIsGloballyActive) {
  //     htmlClasses.push('app--has-global-backdrop');
  //   }
  //   if (this.hasVerticalScrollbar) {
  //     htmlClasses.push('app--has-vertical-scrollbar');
  //   }
  //   return { htmlAttrs: { class: htmlClasses.join(' ') } };
  // },
};
