import { defineNuxtPlugin } from '#imports';
import { openFlyout, closeFlyout, info, flyout } from '@/composables/flyout';

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.provide('flyout', {
    open: openFlyout,
    close: closeFlyout,
    info,
    el: flyout,
  });
});
