import { info } from '~/composables/flyout';

// We export this function to be able to test this in isolation.
export function initHelper() {
  return {
    info,
  };
}

export default initHelper();
