<template>
  <ul class="grid__item grid__item--12 footer-payment">
    <li class="footer-payment__list-item">
      <svg-icon
        :image="visa"
        class="footer-payment__icon footer-payment__icon--visa"
      />
    </li>
    <li class="footer-payment__list-item">
      <svg-icon
        :image="mastercard"
        class="icon footer-payment__icon footer-payment__icon--mastercard"
      />
    </li>
    <li class="footer-payment__list-item">
      <svg-icon
        :image="nachnahme"
        class="footer-payment__icon footer-payment__icon--nachnahme"
      />
    </li>
    <li class="footer-payment__list-item">
      <svg-icon
        :image="paypal"
        class="footer-payment__icon footer-payment__icon--paypal"
      />
    </li>
    <!-- <li class="footer-payment__list-item">
      <nuxt-link
        to="/ratenzahlung"
        class="footer-payment__list-item footer-payment__list-item--ratenkauf"
      >
        <svg-icon
          :image="ratenkauf"
          class="footer-payment__icon footer-payment__icon--ratenkauf"
        />
        Ratenzahlung
      </nuxt-link>
    </li> -->
    <li class="footer-payment__list-item">
      <svg-icon
        :image="dcb"
        class="footer-payment__icon footer-payment__icon--dcb"
      />
    </li>
  </ul>
</template>

<script>
import dhl from '@/assets/images/dhl.svg';
import mastercard from '@/assets/images/payment-logos/mastercard.svg';
import paypal from '@/assets/images/payment-logos/paypal.svg';
import visa from '@/assets/images/payment-logos/visa.svg';
import nachnahme from '@/assets/images/payment-logos/nachnahme.svg';
import ratenkauf from '@/assets/images/payment-logos/ratenkauf.svg';
import dcb from '@/assets/images/payment-logos/dcb-payment.svg';

export default {
  name: 'FooterPayment',
  data() {
    return {
      dhl,
      mastercard,
      paypal,
      visa,
      nachnahme,
      ratenkauf,
      dcb,
    };
  },
};
</script>

<style lang="scss">
@use 'assets/base' as *;

.footer-payment {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 12px;
  margin-bottom: 1.5 * $grid-base;
  @include mq($mq-md) {
    justify-content: center;
  }
  &__list-item {
    list-style: none;
    display: flex;
    justify-content: center;
    flex-basis: 50%;
    align-items: center;

    @include mq($mq-md) {
      padding-right: $grid-base;
      padding-left: $grid-base;
    }

    &--ratenkauf {
      color: white;
      @include font-size(11px);
      &:hover,
      &:active,
      &:visited {
        color: white;
      }
      @include mq($mq-sm) {
        @include font-size(17px);
      }
    }
  }
  &__icon {
    color: color(grey, 18000);
    fill: color(white);
    &--dhl {
      width: 88px;
      height: 23px;
      @include mq($mq-sm) {
        width: 90px;
        height: 24px;
      }
    }
    &--mastercard {
      width: 42px;
      height: 33px;
      @include mq($mq-sm) {
        width: 60px;
        height: 46px;
      }
    }
    &--paypal {
      width: 64px;
      height: 17px;
      @include mq($mq-sm) {
        width: 128px;
        height: 34px;
      }
    }
    &--visa {
      width: 56px;
      height: 18px;
      @include mq($mq-sm) {
        width: 70px;
        height: 23px;
      }
    }
    &--nachnahme {
      width: 75px;
      height: auto;
      @include mq($mq-sm) {
        width: 128px;
      }
    }
    &--ratenkauf {
      width: 30px;
      height: auto;
      margin-right: 6px;
      @include mq($mq-sm) {
        width: 40px;
      }
    }

    &--dcb {
      width: auto;
      height: 38px;
      color: #fff;
    }
  }
  svg {
    font-weight: 400;
    @include font-size(11px, 13px);
  }
}
</style>
