<template>
  <GridColumn
    :xs="12"
    :md="4"
    class="footer-column"
  >
    <Heading
      tag="strong"
      class="footer-column__headline pb--1"
      :data-tealium-rel="`footer.column.${slugify(headline)}`"
    >
      {{ headline }}
    </Heading>
    <div class="footer-column__content">
      <slot></slot>
    </div>
  </GridColumn>
</template>

<script>
import { Heading, GridColumn, slugify } from '@i22-td-smarthome/component-library';

export default {
  name: 'FooterColumn',
  components: {
    Heading,
    GridColumn,
  },
  props: {
    headline: {
      type: String,
      default: '',
    },
  },
  methods: { slugify },
};
</script>

<style lang="scss" scoped>
@use 'assets/base' as *;

.footer-column {
  color: var(--color-secondary);

  &__headline {
    position: relative;
    margin-bottom: $grid-small-gutter;
    color: var(--color-secondary);
    @include font-size(16px, 32px);
    font-weight: 700;
    text-transform: uppercase;
    @include mq($mq-sm) {
      @include font-size(16px, 18px);
    }
  }

  &__content {
    margin-bottom: $grid-small-gutter;
  }
}
</style>
