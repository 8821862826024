import type { GetterTree, ActionTree, MutationTree } from 'vuex';
import type { RootState } from '@/types/store-types';
import { useNuxtApp } from '#imports';

type Downtime = {
  startDate: Date;
  endDate: Date;
  systems: string[];
  maintenanceHeading?: string;
  maintenanceDescription?: string;
  description?: string;
};

const UPDATE_INTERVAL = 60 * 1000; // 1 min.

function downtimeNow(downtime: Downtime) {
  const convertedStartDate = new Date(downtime.startDate);
  const convertedEndDate = new Date(downtime.endDate);
  const timestamp = new Date(downtime.startDate).getTime() / 1000;
  const isDate = timestamp && timestamp > 0 && !Number.isNaN(downtime.startDate);
  if (!isDate) return undefined;

  const dateNow = new Date(Date.now());

  return convertedStartDate < dateNow && convertedEndDate > dateNow;
}

export interface DowntimesState {
  downtimes: Downtime[];
  lastUpdated: number;
}

export const state = (): DowntimesState => ({
  downtimes: [],
  lastUpdated: 0,
});

export const mutations: MutationTree<DowntimesState> = {
  SET_DOWNTIMES(state, downtimes) {
    if (downtimes && Array.isArray(downtimes)) {
      state.downtimes = downtimes;
    } else {
      state.downtimes = [];
    }
  },
  SET_LAST_UPDATED(state) {
    state.lastUpdated = Date.now();
  },
};

export const actions: ActionTree<DowntimesState, RootState> = {
  async fetchDowntimes({ commit, state }) {
    const { $config } = useNuxtApp();
    if (state.lastUpdated + UPDATE_INTERVAL > Date.now()) return;
    try {
      // do not run query twice!
      commit('SET_LAST_UPDATED');

      const host =
        $config.cloudFront.downtimesEnabled && $config.app.cdnURL ? $config.app.cdnURL : '';

      const downtimes = await $shopApi(`${host}/api/downtimes`, {
        loadingSpinner: false,
        noSession: true,
      });

      commit('SET_DOWNTIMES', downtimes?.downtimes || []);
    } catch (error) {
      console.error(error);
    } finally {
      commit('SET_LAST_UPDATED');
    }
  },
};

export const getters: GetterTree<DowntimesState, RootState> = {
  downtimes(state) {
    return state.downtimes;
  },
  maintenanceDowntime(state) {
    return state.downtimes.find(
      (dt) => dt.systems.find((s) => s === 'maintenance') && downtimeNow(dt)
    );
  },
};
