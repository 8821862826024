import fetchOffers, { type ListOffer } from '@/lib/goliath/offers';
import type { ListQuery, OfferFilterParams } from '@/lib/goliath/goliath.types';

export type Promotion<P = any> = P & {
  articleNumber?: string;
  tileGroups?: (string | number)[];
};

export type PromotionOffer<O = ListOffer, P = Promotion> = { offer?: O | undefined } & Promotion<P>;

export const getOffersByGroup = <O = ListOffer, P = Promotion>(
  promotionOffers: PromotionOffer<O, P>[],
  group: string | number,
  count: number | undefined = undefined
): PromotionOffer<O, P>[] => {
  const offerGroup = promotionOffers?.filter(({ tileGroups }) => tileGroups?.includes(group)) || [];
  return count ? offerGroup.slice(0, count) : offerGroup;
};

/**
 * Loads all products by "articleNumber" provided in the "promotions"-Array.
 * Will return all given promotions with an available offer.
 */
export async function loadOffersForPromotions<O, P>(
  promotions: Promotion<P>[],
  inStock = false,
  progress = true,
  onlyWithOffer = true
): Promise<PromotionOffer<O, P>[]> {
  try {
    const filter: OfferFilterParams = {
      articleNumbers: promotions
        .map(({ articleNumber }: Promotion) => articleNumber)
        .filter(Boolean),
    };
    if (inStock) {
      filter.productAvailable = true;
    }

    const offersFromGoliath: ListQuery['offers'] | undefined =
      (filter.articleNumbers?.length || 0) > 0
        ? await fetchOffers({ filter, includeAggregations: false }, progress)
        : undefined;

    const responsePromotions: PromotionOffer<O, P>[] = [];
    promotions.forEach((productTeaser: Promotion) => {
      const offer = offersFromGoliath?.entries?.find(
        (o) => o?.product?.articleNumber === productTeaser.articleNumber
      );
      if (offer) {
        responsePromotions.push({
          ...productTeaser,
          offer,
        });
      }
      // return all teasers without articleNumber.
      else if (!productTeaser.articleNumber || !onlyWithOffer || productTeaser.showWithoutOffer) {
        responsePromotions.push({
          ...productTeaser,
          offer: undefined,
        });
      }
    });
    return responsePromotions;
  } catch (_e) {}
  return [];
}
