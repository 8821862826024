import { uniqBy } from 'lodash-es';
import type { ListOffer } from '@/lib/goliath/offers';
import type { ScopedOffer } from '@/lib/goliath/goliath.types';
import { getGoliathImages, type LocalImage } from '@i22/rocket';

export const getImagesFromOffer = <O extends ListOffer | ScopedOffer>(offer: O): LocalImage[] => {
  if (!offer) return [];
  const baseImages = 'media' in offer ? offer?.media || [] : [];
  const product = offer?.product || {};
  const bundledProducts = 'bundledProducts' in product ? product.bundledProducts || [] : [];
  const additionalImages = bundledProducts.map((i) => i?.teaserImage);
  const uniqueAdditionalImages = uniqBy(additionalImages, 'path');

  return getGoliathImages({
    // @ts-ignore
    media: [...baseImages, ...uniqueAdditionalImages],
  });
};

export const getMainImageFromOffer = <O extends ListOffer | ScopedOffer>(offer: O, index = 0) => {
  const offerImages = getImagesFromOffer(offer);
  if (offerImages.length < index + 1) return undefined;
  return offerImages[index];
};
