import { useRuntimeConfig } from '#imports';
import { type LocalImage } from '@i22/rocket';

export type SimpleSlugMapping = {
  crypticalSlug: string; // The cryptical URL slug for the moments page.
  slug: string; // The actual goliath URL slug.
};

export type BundleSlugMapping = {
  crypticalSlug: string; // The cryptical URL slug for the moments page.
  bundleNumbers?: string[]; // article numbers to build the bundle of.
  name: string; // output name for the bundle
  additionalImages?: Pick<LocalImage, 'name' | 'large' | 'medium' | 'small'>[]; // optional, images for the bundle.
  showOnlyCompleteBundle: boolean; // only when all bundleNumbers are in goliath with stock, the bundle is shown.
};

export type MagentaMomentsEntry = {
  articleNumbers: string[]; // Defines article numbers that are not allowed to add to cart.
  slugMapping?: (SimpleSlugMapping | BundleSlugMapping)[]; // Mapping for cryptical URLs and bundles.
  validAddToCartUrls?: string[]; // Defines the browser URLs allowed to add the product to the cart. (should be (path/CRYPTICALSLUG)
  addToCartRejectionNote?: string; // Note displayed when add to cart is rejected.
  envName: string; // envName - Environment variable name to enable the add to cart prevention.
};

const DEFAULT_NOTE =
  'Aktuell kann dieser Artikel nur über Magenta Moments in der "MeinMagenta" App bestellt werden. Weitere Informationen finden Sie <a href="https://www.telekom.de/unterwegs/aktionen?activeSectionId=magenta-moments">hier</a>.';
export const magentaMomentOffers: MagentaMomentsEntry[] = [
  // Apple AirPods Pro (2.Gen.) MM
  {
    articleNumbers: ['99934801'],
    slugMapping: [
      {
        crypticalSlug: '00ed8352f3a7252f78730bc5d1eab293bc05c50e',
        slug: 'apple-airpods-pro-2-generation-mit-magsafe-ladecase-usb-c-weiss',
      },
    ],
    validAddToCartUrls: ['/magenta-moments-2024/00ed8352f3a7252f78730bc5d1eab293bc05c50e'],
    addToCartRejectionNote: DEFAULT_NOTE,
    envName: 'appleAirpods2Pro',
  },

  // AirTags 4er MM
  {
    articleNumbers: ['99932114'],
    slugMapping: [
      {
        crypticalSlug: '9485a44de9a491f71859a57c9b35ce64a697895c',
        slug: 'apple-airtag-4er-pack-weiss',
      },
    ],
    validAddToCartUrls: ['/magenta-moments-2024/9485a44de9a491f71859a57c9b35ce64a697895c'],
    addToCartRejectionNote: DEFAULT_NOTE,
    envName: 'appleAirtag',
  },

  // Anker Bundle MM
  {
    // 99935130: anker-30w-usb-c-nano-4-ladegerat-schwarz
    // 99935134: anker-30w-nano-powerbank-10-000-mah-schwarz
    // 99935132: anker-bio-based-usb-c-auf-usb-c-kabel-180-cm-schwarz
    slugMapping: [
      {
        crypticalSlug: 'eb6cee162e5da160f74d6e261dbfd5de8b05c2f5',
        bundleNumbers: ['99935130', '99935134', '99935132'],
        name: 'Anker (3er-Set)',
        showOnlyCompleteBundle: false,
        additionalImages: [
          {
            name: 'Anker (3er-Set)',
            small:
              '/content/content-images/magenta-moments/2024/3a8374ffe463aa1a05f3a910531b5674/anker-lade-bundle.png',
            medium:
              '/content/content-images/magenta-moments/2024/3a8374ffe463aa1a05f3a910531b5674/anker-lade-bundle.png',
            large:
              '/content/content-images/magenta-moments/2024/3a8374ffe463aa1a05f3a910531b5674/anker-lade-bundle.png',
          },
        ],
      },
    ],
    articleNumbers: ['99935132', '99935134', '99935130'],
    validAddToCartUrls: ['/magenta-moments-2024/eb6cee162e5da160f74d6e261dbfd5de8b05c2f5'],
    addToCartRejectionNote: DEFAULT_NOTE,
    envName: 'ankerBundle',
  },

  // AirPods Max
  {
    articleNumbers: ['99936039', '99936037'],
    slugMapping: [
      {
        crypticalSlug: '63bf1abbaf3895b0ba023b38b8bb6948191bfb06',
        slug: 'apple-airpods-max-schwarz',
      },
      {
        crypticalSlug: '6eab85001688066fb5d8c2030382bd94cf5de688',
        slug: 'apple-airpods-max-polarstern',
      },
    ],
    validAddToCartUrls: [
      '/magenta-moments-2024/63bf1abbaf3895b0ba023b38b8bb6948191bfb06',
      '/magenta-moments-2024/6eab85001688066fb5d8c2030382bd94cf5de688',
    ],
    addToCartRejectionNote: DEFAULT_NOTE,
    envName: 'appleAirpodsMax',
  },

  // Beats Pill schwarz
  {
    articleNumbers: ['99936061'],
    slugMapping: [
      {
        crypticalSlug: 'b3e8243f33943ae86df84fd102e7103a93e9abac',
        slug: 'beats-pill-bluetooth-lautsprecher-schwarz',
      },
    ],
    validAddToCartUrls: ['/magenta-moments-2024/b3e8243f33943ae86df84fd102e7103a93e9abac'],
    addToCartRejectionNote: DEFAULT_NOTE,
    envName: 'beatsPill',
  },
];

export const getEntryByCrypticalSlug = (
  crypticalSlug: string | undefined
): MagentaMomentsEntry | undefined => {
  if (!crypticalSlug) return undefined;
  return magentaMomentOffers.find((o) => {
    if (!o.slugMapping) return false;
    return o.slugMapping.find((mapping) => mapping.crypticalSlug === crypticalSlug);
  });
};

export const getMagentaMomentOffer = (
  articleNumber: string | undefined
): MagentaMomentsEntry | undefined => {
  if (!articleNumber) return undefined;
  return magentaMomentOffers.find((o) => o.articleNumbers.includes(articleNumber));
};

// Checks if an article number is allowed to be added to the cart based on the current URL path.
export const isAllowedToAddToCart = (articleNumber: string | undefined | null, path: string) => {
  if (!articleNumber) return false;
  const offer = getMagentaMomentOffer(articleNumber);
  if (!offer) return true;
  if (offer.validAddToCartUrls) {
    // The current route is an allowed url
    if (offer.validAddToCartUrls.find((url) => path.startsWith(url))) return true;

    try {
      // !! This ist not be available in nuxt dev ssr-context !! In nuxt 3 it will work
      const performanceMode = (useRuntimeConfig().public?.performanceMode || {}) as Record<
        string,
        boolean | any
      >;
      return !performanceMode?.offerGroups?.[offer.envName];
    } catch (e) {}
  }
  return true;
};
