import { type CustomVue } from 'vuex';
import { ofetch } from 'ofetch';
import type { FetchOptions, FetchContext } from 'ofetch/dist';
import { defineNuxtPlugin } from '#imports';
import type { ShopApiClient } from '~/types/shop-api';

declare global {
  // eslint-disable-next-line vars-on-top, no-var
  var $shopApi: ShopApiClient;
}

interface CustomFetchContext extends FetchContext {
  loadingSpinner?: boolean;
}

interface CustomHeaders extends Headers {
  'x-csrf-token'?: string;
}

interface CustomFetchOptions extends FetchOptions {
  Headers?: CustomHeaders;
  onRequest?(context: CustomFetchContext): Promise<void> | void;
}

interface CustomFetchRequestOptions extends FetchOptions {
  loadingSpinner?: boolean;
  noSession?: boolean; // Disable session e.g. for downtimes / redirects,
  headers?: CustomHeaders;
}

// We declare $shopApi as global. We cannot use native $fetch, cause the x-csrf-token won't be handled.
const buildShopApiClient = (store: CustomVue) => {
  // const { start, finish } = useLoadingIndicator();
  const start = () => store.dispatch('loader/start');
  const finish = () => store.dispatch('loader/stop');

  return ofetch.create({
    headers: {
      Accept: 'application/vnd.td.eg-solo.v1+json',
      'Content-Type': 'application/json',
    },
    onResponse({ response }) {
      const csrfToken = response.headers.get('x-csrf-token');
      if (csrfToken) {
        store.commit('cart/SET_CSRF_TOKEN', csrfToken);
      }
      finish();
    },

    onRequest({ options }: { options: CustomFetchRequestOptions }) {
      if (options.loadingSpinner !== false) {
        start();
      }
      if (options.noSession !== true && store.state.cart.csrfToken) {
        if (!options.headers) {
          // eslint-disable-next-line no-param-reassign
          options.headers = new Headers();
        }
        // eslint-disable-next-line no-param-reassign
        options.headers['x-csrf-token'] = store.state.cart.csrfToken;
      }
    },
    onResponseError({ response }) {
      const csrfToken = response?.headers?.get('x-csrf-token');
      if (csrfToken) {
        store.commit('cart/SET_CSRF_TOKEN', csrfToken);
      }
      finish();
    },
    onRequestError(context) {
      finish();
    },
  } as CustomFetchOptions);
};

export default defineNuxtPlugin((nuxtApp) => {
  const store = nuxtApp.$store as CustomVue;
  globalThis.$shopApi = buildShopApiClient(store);
});
