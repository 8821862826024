import { defineNuxtPlugin } from '#imports';
import { openModal, closeModal, modal } from '@/composables/modal';

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.provide('modal', {
    open: openModal,
    close: closeModal,
    el: modal,
  });
});
