<template>
  <GridContainer
    small
    class="footer-columns"
  >
    <GridRow>
      <FooterColumn
        headline="Wir helfen Dir weiter"
        class="footer-columns__column"
      >
        <ul class="footer-columns__list list">
          <li
            v-for="(helpLink, index) in helpLinks"
            :key="index"
            class="list__element list__element--icon"
          >
            <LinkButton
              class="link footer-columns__link"
              tag="nuxt-link"
              no-styling
              :to="helpLink.to"
              :is-external="helpLink.isExternal"
              :rel="helpLink.isExternal ? 'noopener' : false"
              :tealium-suffix="tealiumSuffix"
            >
              <SvgIcon
                :image="helpLink.icon"
                class="icon list__icon"
              />
              <span>{{ helpLink.text }}</span>
            </LinkButton>
          </li>
        </ul>
      </FooterColumn>
      <FooterColumn
        headline="Sicher online bestellen und bezahlen"
        class="footer-columns__column"
      >
        <ul class="footer-columns__list list">
          <li class="list__element list__element--dashed">Geprüfte Sicherheit durch TeleSec</li>
          <li class="list__element list__element--dashed">
            Sichere Zahlung mit SSL-Verschlüsselung
          </li>
          <li class="list__element list__element--dashed">Schnelle Lieferzeiten</li>
          <li class="list__element list__element--dashed">Datenschutz nach deutschem Recht</li>
        </ul>
        <FooterPayment />
      </FooterColumn>
      <FooterColumn
        headline="Häufig gesuchte Hersteller"
        class="footer-columns__column"
      >
        <FooterFeaturedBrands />
      </FooterColumn>
    </GridRow>
  </GridContainer>
</template>

<script>
import { GridContainer, GridRow, LinkButton } from '@i22-td-smarthome/component-library';

import IconFacebook from '@/assets/images/facebook.svg';
import IconNewsletter from '@/assets/images/icon-newsletter.svg';
import IconPhone from '@/assets/images/phone.svg';
import IconSupport from '@/assets/images/icon-support.svg';
import IconTelekomHilft from '@/assets/images/telekom-hilft.svg';
import IconTelekomShop from '@/assets/images/icon-shop.svg';
import IconHandyHilfe from '@/assets/images/icon-handyhilfe.svg';
import IconRetoure from '@/assets/images/icon-retoure.svg';
import IconTwitter from '@/assets/images/twitter.svg';

import FooterColumn from '@/components/footer-column.vue';
import FooterPayment from '@/components/footer-payment.vue';
import FooterFeaturedBrands from '@/components/footer-featured-brands.vue';
import SvgIcon from '@/components/svg-icon.vue';

export default {
  name: 'FooterColumns',
  components: {
    GridContainer,
    GridRow,
    LinkButton,
    FooterFeaturedBrands,
    FooterColumn,
    FooterPayment,
    SvgIcon,
  },
  data() {
    return {
      tealiumSuffix: 'footer',
      helpLinks: [
        {
          icon: IconPhone,
          isExternal: false,
          to: '/kontakt',
          text: 'Kontakt',
        },
        {
          icon: IconRetoure,
          isExternal: false,
          to: '/hilfe/lieferstatus-und-retoure',
          text: 'Lieferstatus & Retoure',
        },
        {
          icon: IconFacebook,
          isExternal: true,
          to: 'https://www.facebook.com/telekomhilft/',
          text: 'Telekom hilft auf Facebook',
        },
        {
          icon: IconTwitter,
          isExternal: true,
          to: 'https://twitter.com/telekom_hilft?lang=de',
          text: 'Telekom hilft auf Twitter',
        },
        {
          icon: IconTelekomHilft,
          isExternal: true,
          to: 'https://telekomhilft.telekom.de/',
          text: 'Telekom hilft Community',
        },
        {
          icon: IconTelekomShop,
          isExternal: true,
          to: 'https://shopsuche.telekomshop.de/',
          text: 'Telekom Shop finden',
        },
        {
          icon: IconSupport,
          isExternal: true,
          to: 'https://www.telekom.de/hilfe/geraete-zubehoer/handy-hilfe',
          text: 'Hilfen für Dein Handy, Smartphone und Tablet',
        },
        {
          icon: IconNewsletter,
          isExternal: true,
          to: 'https://www.telekom.de/mehr-magenta/newsletter',
          text: 'Telekom Newsletter',
        },
        {
          icon: IconHandyHilfe,
          isExternal: true,
          to: 'https://www.telekom.de/zubuchoptionen/festnetz',
          text: 'Persönliche Unterstützung für Dein Handy & Co.',
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@use 'assets/base' as *;

.footer-columns {
  margin-bottom: -$grid-small-gutter;

  &__column {
    margin-bottom: $grid-small-gutter;
  }

  &__list {
    @include font-size(16px, 27px);
    display: inline-block;
    margin-bottom: $grid-medium-gutter;
    // Override
    .list__element {
      padding-bottom: 10px;
    }

    :deep(.link-button__icon) {
      display: none;
    }
  }

  &__link {
    white-space: initial;
    color: color(white);
  }

  &__icon {
    height: 4.5rem;
    margin-bottom: calculate-rem(10px);
    margin-right: calculate-rem(20px);
    width: 4.5rem;
    float: left;
  }
}
</style>
