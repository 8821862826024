import Vue from 'vue';
import trackingFb from '@/mixins/tracking-fb';

// Load mixin only once
if (!Vue.trackingFBMixin) {
  Vue.trackingFBMixin = true;
  Vue.mixin(trackingFb);
}

export default () => {};
