import type { Staggered } from '@/store/offers';
import type { PaymentFrequency, Price, PriceType } from '@/lib/goliath/goliath.types';
import { getPrice } from '@i22/rocket';

const DEFAULT_REBATE = {
  amount: 0,
  percentage: 0,
};

const calculateDiscountedAmount = (amount: number, percentage: number) =>
  Math.round(amount * (1 - percentage / 100));

export const getRebatePercentage = (staggeredRebates: Staggered[], selectedQuantity: number) => {
  const relevantRebate = staggeredRebates.reduce((accumulator, currentValue) => {
    if (currentValue.amount <= selectedQuantity && currentValue.amount > accumulator.amount)
      return currentValue;
    return accumulator;
  }, DEFAULT_REBATE);
  return relevantRebate.percentage;
};

export const getRebatePrices = (originalPrices: Price[], percentage: number) => {
  if (percentage === 0) return originalPrices;
  const defaultPrice = getPrice(originalPrices, 'DEFAULT', 'ONETIME');
  if (!defaultPrice?.amount) return originalPrices;

  const newPrices: Price[] = [];

  const discountedAmount = calculateDiscountedAmount(defaultPrice.amount, percentage);
  newPrices.push(
    {
      __typename: 'Price',
      amount: discountedAmount,
      currency: defaultPrice.currency,
      paymentFrequency: 'ONETIME' as PaymentFrequency.Onetime,
      type: 'DEFAULT' as PriceType.Default,
      value: discountedAmount / 100,
    },
    {
      __typename: 'Price',
      amount: defaultPrice.amount,
      currency: defaultPrice.currency,
      paymentFrequency: 'ONETIME' as PaymentFrequency.Onetime,
      type: 'WAS' as PriceType.Was,
      value: defaultPrice.value,
    }
  );
  return newPrices;
};
